<script>
    import { _ } from "svelte-i18n";
    import HeroComponent from "../hero/component.svelte";
    import CloudbedsComponent from "./component.svelte";
    import BenefitsComponent from "../benefits/component.svelte";
    import ProductsComponent from "../products/component.svelte";
    import MoreCloubedsComponent from "./more.component.svelte";
    import ContactComponent from "../contact/component.svelte";

    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
</script>

<svelte:head>
    <title>{$_("Cloudbeds - GuestHub")}</title>
    <meta property="og:title" content={$_("Cloudbeds - GuestHub")} />
</svelte:head>

<HeroComponent />
<CloudbedsComponent />
<BenefitsComponent />
<ProductsComponent />
<!-- <MoreCloubedsComponent /> -->
<ContactComponent />
