<script>
    import BenefitUnoComponent from "./1.component.svelte";
    import BenefitDosComponent from "./2.component.svelte";
    import BenefitTresComponent from "./3.component.svelte";
</script>

<section id="ReviewFiltering" class="display-des">
    <section
        class="testimonail area pt-200 pb-200 pt-sm-80 pb-sm-80 "
        data-background="img/bg/about-bg1.jpg"
    >
        <BenefitUnoComponent />
    </section>

    <section id="ParetoPrinciple"
        class="testimonail area pt-200 pb-200 pt-sm-80 pb-sm-80" style="background: #f4f4f4;"
        data-background="img/bg/about-bg1.jpg"
    >
        <BenefitDosComponent />
    </section>

    <section id="OwnYourGuests"
        class="testimonail area pt-200 pb-200 pt-sm-80 pb-sm-80"
        data-background="img/bg/about-bg1.jpg"
    >
        <BenefitTresComponent />
    </section>
</section>
