<script>
    import HeroComponent from "./hero.component.svelte";
    import BenefitsComponent from "./benefits/component.svelte";
    import IntegrationsComponent from "../../integrations/component.svelte";
    import { onMount } from "svelte";
    import { activeRoute, redirect } from "../../pager";

    if ($activeRoute.path && $activeRoute.path.includes("/one-page/1")) {
        redirect("/one-page/better-ota");
    }

    onMount(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    });
</script>

<svelte:head>
    <title>GuestHub</title>
    <meta property="og:title" content="GuestHub" />
</svelte:head>

<HeroComponent />
<BenefitsComponent />
<IntegrationsComponent onlyEnglish={true} />
