<script>
  import { onMount } from "svelte";
  import { activeRoute } from "../pager";
  import { environments } from "../assets/environments";
  import { ga } from "@beyonk/svelte-google-analytics";
  import {
    useForm,
    required,
    validators,
    email,
    maxLength,
    Hint,
    HintGroup,
    minLength,
  } from "svelte-use-form";

  export let stickyHeader;
  const dataForm = useForm();
  let currentStep = 0;

  const totalSteps = 5; // Asumiendo que tienes 3 pasos en tu formulario
  let cuestions = {
    name: $activeRoute.queryParams.name,
    email: $activeRoute.queryParams.email,
    lastStep: currentStep || 0,
    steps: ["", "", "", "", ""],
    otherSoftware: "",
    otherPMS: "",
  };
  const softwareHotel = ["Alice", "Duve", "myHotel", "Guest Joy", "Canary"];
  const softwarePMS = ["Infor", "Opera", "Cloudbeds", "SkyTouch", "Mews"];
  let disabledButton = false;
  let sendingEmail = false;
  let errorSending = false;
  let errorSurvey = false;
  let loading = true;
  let btnActionGo;
  let elementExample3Inother;
  let elementExample4Inother;

  setTimeout(() => (stickyHeader = true), 100);

  // Función para avanzar al siguiente paso
  const nextStep = () => {
    if (currentStep < totalSteps && cuestions.steps[currentStep]) {
      ++currentStep;
      if (cuestions.lastStep < currentStep) {
        cuestions.lastStep = currentStep;
      }
    }
    return true;
  };

  // Función para retroceder al paso anterior
  const previousStep = () => {
    if (currentStep > 0) {
      --currentStep;
      if (currentStep == 4 && cuestions.steps[0] == "yes") {
        currentStep = 1;
      } else if (currentStep == 3 && cuestions.steps[2] == "no") {
        --currentStep;
      } else if (currentStep == 1 && cuestions.steps[0] == "no") {
        --currentStep;
      }
    }
  };

  const backToNextStep = () => {
    if (currentStep < cuestions.lastStep) {
      if (currentStep == 0 && cuestions.steps[0] == "no") {
        ++currentStep;
      } else if (
        currentStep == 1 &&
        cuestions.steps[0] == "yes" &&
        cuestions.steps[1]
      ) {
        currentStep = 4;
      } else if (currentStep == 2 && cuestions.steps[2] == "no") {
        ++currentStep;
      }
      ++currentStep;
    }
  };

  // Lógica para manejar el scroll y cambiar de paso
  onMount(() => {
    loading = true;
    if (cuestions.email && cuestions.name) {
      fetch(`${environments.api}/survey/march-2024?email=${cuestions.email}`, {
        method: "GET",
        headers: {
          "content-type": "application/json",
        },
      })
        .then((response) =>
          response.ok ? true : Promise.reject(response.text()),
        )
        .then(() => {
          if (window.location.hostname.includes("guesthub.io")) {
            ga.addEvent("open_march_24", true);
          }
          loading = false;
        })
        .catch((err) => {
          if (err) {
            err.then((response) => {
              btnActionGo = response;
              sendingEmail = true;
              loading = false;
            });
          } else {
            btnActionGo = response;
            sendingEmail = true;
            loading = false;
          }
        });
    } else {
      errorSurvey = true;
      loading = false;
    }
  });

  const changeSelect = (index) => (event) => {
    cuestions.steps[currentStep] = event.target.value;
    if ((index == 3 || index == 4) && event.target.value == "Other") {
      setTimeout(() => {
        if (index == 3) {
          elementExample3Inother.focus();
        } else if (index == 4) {
          elementExample4Inother.focus();
        }
      }, 0);
    } else {
      nextStep();
      if (index == 0 && event.target.value == "no") {
        ++currentStep;
      } else if (index == 1) {
        currentStep = 5;
      } else if (index == 2 && event.target.value == "no") {
        ++currentStep;
      }
      //if (cuestions.lastStep < currentStep) {
      cuestions.lastStep = currentStep;
      //}
      for (let index = currentStep; index < cuestions.steps.length; ++index) {
        cuestions.steps[index] = null;
      }
    }
  };

  const hangleSubmit = (event) => {
    if (
      (cuestions.steps[0] == "yes" && cuestions.steps[1]) ||
      (cuestions.steps[0] == "no" &&
        ((cuestions.steps[2] == "yes" && cuestions.steps[3]) ||
          (cuestions.steps[2] == "no" && cuestions.steps[4])))
    ) {
      if (window.location.hostname.includes("guesthub.io")) {
        ga.addEvent("send_survey_march_24", true);
      }
      disabledButton = true;
      errorSending = false;
      errorSurvey = false;
      fetch(`${environments.api}/survey/march-2024`, {
        method: "POST",
        body: JSON.stringify(cuestions),
        headers: {
          "content-type": "application/json",
        },
      })
        .then((response) =>
          response.ok ? response.text() : Promise.reject(response),
        )
        .then((response) => {
          btnActionGo = response;
          sendingEmail = true;
        })
        .catch((err) => {
          console.error(err);
          disabledButton = false;
          errorSurvey = true;
          errorSending = true;
        });
    }
  };

  let lastScrollTime = 0;

  const handleWheel = (event) => {
    const currentTime = Date.now();
    if (currentTime - lastScrollTime > 1000) {
      lastScrollTime = currentTime;
      if (event.deltaY < 0) {
        previousStep();
      } else {
        backToNextStep();
      }
    }
  };
</script>

<section class="vh-100 d-flex align-items-center justify-content-center" on:wheel={handleWheel} style="padding-top: 100px;">
  <div class="container">
    <div class="col-12">
      <div class="card col-12 align-items-center">
        {#if loading}
          <div
            class="spinner-border"
            style="width: 6rem; height: 6rem; color: #1AB394;"
            role="status"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
        {:else if sendingEmail}
          <div class="text-center">
            <i class="material-icons" style="font-size:6rem;color: #1AB394;"
              >check_circle</i
            >
            <h2 style="font-size: 3rem;" class="mt-3">
              Thank you for your time!
            </h2>
            <p style="font-size: 1rem;">
              We want to share with you our the best strategy for your property
            </p>
            <a
              href={btnActionGo}
              class="btn btn-primary btn-sm"
              style="font-size: 1rem; background-color: #1AB394;"
            >
              This is our strategy for you
            </a>
          </div>
        {:else if errorSurvey}
          <div class="text-center">
            <img src="/img/error-survey-march-24.png" alt="" />
            <h2 style="font-size: 3rem;" class="mt-3">
              Sorry, an error has occurred
            </h2>

            {#if errorSending}
              <button
                class="btn btn-primary btn-sm"
                type="submit"
                style="font-size: 1rem; background-color: #1AB394"
                disabled={disabledButton}
                on:click|preventDefault={hangleSubmit}
              >
                Reintentar
              </button>
            {/if}
          </div>
        {:else}
          <form
            use:dataForm
            class="col-12"
            on:submit|preventDefault={hangleSubmit}
          >
            {#if currentStep <= 2}
              <div class="card-header" class:disabled={currentStep > 0}>
                <h1 class="card-title title-survey">Invitación a campaña</h1>
                <p>
                  Descripción de campaña Descripción de campaña Descripción de
                  campaña Descripción de campaña Descripción de campaña
                  Descripción de campaña
                </p>
              </div>
            {/if}

            <!-- currentStep === 0 -->
            {#if currentStep <= 2}
              <div class="card-body active" class:disabled={currentStep > 0}>
                <div class="mb-3" style="margin-top: 4; margin-bottom:6rem">
                  <h4 class="card-title">
                    Is your property on the 1st page of most if not all OTA’s in
                    your area?
                  </h4>
                  <div class="row">
                    <div class="col-6 form-check">
                      <label
                        class:border-active={cuestions.steps[0] == "yes"}
                        class="form-check-label w-100 option-radio-button mr-1"
                        for="radioExample01"
                      >
                        <input
                          class="radioExample01"
                          type="radio"
                          name="radioExample0"
                          id="radioExample01"
                          value="yes"
                          checked={cuestions.steps[0] == "yes"}
                          on:change={changeSelect(0)}
                        />
                        <span class="questions"> Yes </span>
                      </label>
                    </div>
                    <div class="col-6 form-check">
                      <label
                        class:border-active={cuestions.steps[0] == "no"}
                        class="form-check-label w-100 option-radio-button"
                        for="radioExample02"
                      >
                        <input
                          class="radioExample01"
                          type="radio"
                          name="radioExample0"
                          id="radioExample02"
                          value="no"
                          checked={cuestions.steps[0] == "no"}
                          on:change={changeSelect(0)}
                        />
                        <span class="questions"> No </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            {/if}

            <!-- currentStep === 1 -->
            {#if cuestions.lastStep > 0 && currentStep <= 5 && cuestions.steps[0] == "yes"}
              <div class="card-body active" class:disabled={currentStep != 1}>
                <div class="mb-3">
                  <h4 class="card-title">How are you getting there?</h4>
                  <div class="row">
                    <div class="col-6 form-check">
                      <label
                        class:border-active={cuestions.steps[1] ==
                          "excelent-review"}
                        class="form-check-label w-100 option-radio-button"
                        for="radioExample11"
                      >
                        <input
                          class="radioExample01"
                          type="radio"
                          name="radioExample1"
                          id="radioExample11"
                          value="excelent-review"
                          checked={cuestions.steps[1] == "excelent-review"}
                          on:change={changeSelect(1)}
                        />
                        <span class="questions"> Excellent reviews </span>
                      </label>
                    </div>
                    <div class="col-6 form-check">
                      <label
                        class:border-active={cuestions.steps[1] == "paid"}
                        class="form-check-label w-100 option-radio-button mr-1"
                        for="radioExample12"
                      >
                        <input
                          class="radioExample01"
                          type="radio"
                          name="radioExample1"
                          id="radioExample12"
                          value="paid"
                          checked={cuestions.steps[1] == "paid"}
                          on:change={changeSelect(1)}
                        />
                        <span class="questions"> Paying extra commission </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            {/if}

            <!-- currentStep === 2 -->
            {#if cuestions.lastStep > 1 && currentStep >= 0 && (cuestions.steps[2] == "yes" ? currentStep <= 3 : currentStep <= 4) && cuestions.steps[0] == "no"}
              <div class="card-body active" class:disabled={currentStep != 2}>
                <div class="mb-3">
                  <h4 class="card-title">
                    Does your hotel use a system to maximize the satisfaction of
                    your guests?
                  </h4>
                  <div class="row">
                    <div class="col-6 form-check">
                      <label
                        class:border-active={cuestions.steps[2] == "yes"}
                        class="form-check-label w-100 option-radio-button"
                        for="radioExample21"
                      >
                        <input
                          class="radioExample01"
                          type="radio"
                          name="radioExample2"
                          id="radioExample21"
                          value="yes"
                          checked={cuestions.steps[2] == "yes"}
                          on:change={changeSelect(2)}
                        />
                        <span class="questions"> Yes </span>
                      </label>
                    </div>
                    <div class="col-6 form-check">
                      <label
                        class:border-active={cuestions.steps[2] == "no"}
                        class="form-check-label w-100 option-radio-button"
                        for="radioExample22"
                      >
                        <input
                          class="radioExample01"
                          type="radio"
                          name="radioExample2"
                          id="radioExample22"
                          value="no"
                          checked={cuestions.steps[2] == "no"}
                          on:change={changeSelect(2)}
                        />

                        <span class="questions"> No </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            {/if}

            <!-- currentStep === 3 -->
            {#if cuestions.lastStep > 2 && currentStep >= 2 && currentStep <= 4 && cuestions.steps[0] == "no" && cuestions.steps[2] == "yes"}
              <div class="card-body active" class:disabled={currentStep != 3}>
                <div class="mb-3">
                  <h4 class="card-title">What system does your hotel use?</h4>
                  <div class="row">
                    {#each softwareHotel as software, index (software)}
                      <div class="col-6 form-check">
                        <label
                          class:border-active={cuestions.steps[3] == software}
                          class="form-check-label w-100 option-radio-button-Pms"
                          for="radioExample3{index}"
                        >
                          <input
                            class="radioExample01"
                            type="radio"
                            name="radioExample3"
                            id="radioExample3{index}"
                            value={software}
                            checked={cuestions.steps[3] == software}
                            on:change={changeSelect(3)}
                          />
                          <span class="questions">
                            {software}
                          </span>
                        </label>
                      </div>
                    {/each}

                    <div class="col-6 form-check">
                      <label
                        class:border-active={cuestions.steps[3] == "Other"}
                        class="form-check-label w-100 option-radio-button-Pms"
                        for="radioExample3other"
                      >
                        <input
                          class="radioExample01"
                          type="radio"
                          name="radioExample3"
                          id="radioExample3other"
                          value="Other"
                          checked={cuestions.steps[3] == "Other"}
                          on:change={changeSelect(3)}
                        />
                        <span class="questions"> Other </span>
                      </label>
                    </div>

                    {#if cuestions.steps[3] == "Other"}
                      <div
                        class="col-s12 mb-3"
                        style="margin-top: 1rem; width: 48%; "
                      >
                        <label
                          for="radioExample3Inother"
                          class="form-label card-title {!$dataForm
                            .radioExample3Inother?.valid &&
                          $dataForm.radioExample3Inother?._touched
                            ? 'field-invalid'
                            : ''}"
                        >
                          Which one?
                        </label>
                        <input
                          use:validators={[required, maxLength(50)]}
                          type="text"
                          class="form-control {!$dataForm.radioExample3Inother
                            ?.valid && $dataForm.radioExample3Inother?._touched
                            ? 'is-invalid'
                            : ''}"
                          id="radioExample3Inother"
                          name="radioExample3Inother"
                          bind:this={elementExample3Inother}
                          bind:value={cuestions.otherSoftware}
                          on:keydown={(e) =>
                            e.key === "Enter"
                              ? cuestions.otherSoftware &&
                                cuestions.otherSoftware.toString().trim()
                                  .length > 0
                                ? nextStep() && e.preventDefault()
                                : ($dataForm.radioExample3Inother._touched =
                                    true && e.preventDefault())
                              : undefined}
                          required
                          maxlength="50"
                          autocomplete="off"
                        />
                        {#if !$dataForm.radioExample3Inother?.touched || ($dataForm.radioExample3Inother?.touched && $dataForm.radioExample3Inother.valid)}
                          <div class="form-text">
                            Please press enter to continue
                          </div>
                        {:else}
                          <HintGroup for="radioExample3Inother">
                            <Hint
                              class="help-block help-block-left"
                              on="required"
                            >
                              <span class="field-invalid">
                                This field is required
                              </span>
                            </Hint>
                            <Hint
                              class="help-block help-block-left"
                              on="maxLength"
                              hideWhenRequired
                              let:value
                            >
                              <span class="field-invalid">
                                {"Maximo [X] caracteres".replace("[X]", value)}
                              </span>
                            </Hint>
                          </HintGroup>
                        {/if}
                      </div>
                    {/if}
                  </div>
                </div>
              </div>
            {/if}

            <!-- currentStep === 4 -->
            {#if cuestions.lastStep > 3 && (cuestions.steps[2] == "yes" ? currentStep >= 3 : currentStep >= 2) && currentStep <= 5 && cuestions.steps[0] == "no"}
              <div class="card-body active" class:disabled={currentStep != 4}>
                <div class="mb-3">
                  <h4 class="card-title">What PMS does your hotel use?</h4>
                  <div class="row">
                    {#each softwarePMS as software, index (software)}
                      <div class="col-6 form-check">
                        <label
                          class:border-active={cuestions.steps[4] == software}
                          class="form-check-label w-100 option-radio-button-Pms"
                          for="radioExample4{index}"
                        >
                          <input
                            class="radioExample01"
                            type="radio"
                            name="radioExample4"
                            id="radioExample4{index}"
                            value={software}
                            checked={cuestions.steps[4] == software}
                            on:change={changeSelect(4)}
                          />
                          <span class="questions">
                            {software}
                          </span>
                        </label>
                      </div>
                    {/each}

                    <div class="col-6 form-check">
                      <label
                        class:border-active={cuestions.steps[4] == "Other"}
                        class="form-check-label w-100 option-radio-button-Pms"
                        for="radioExample4other"
                      >
                        <input
                          class="radioExample01"
                          type="radio"
                          name="radioExample4"
                          id="radioExample4other"
                          value="Other"
                          checked={cuestions.steps[4] == "Other"}
                          on:change={changeSelect(4)}
                        />
                        <span class="questions"> Other </span>
                      </label>
                    </div>

                    {#if cuestions.steps[4] == "Other"}
                      <div
                        class="col-s12 mb-12"
                        style="margin-top: 1rem; width: 48%; "
                      >
                        <label
                          for="radioExample4Inother"
                          class="form-label card-title {!$dataForm
                            .radioExample4Inother?.valid &&
                          $dataForm.radioExample4Inother?._touched
                            ? 'field-invalid'
                            : ''}"
                        >
                          Which one?
                        </label>
                        <input
                          use:validators={[required, maxLength(50)]}
                          type="text"
                          class="form-control {!$dataForm.radioExample3Inother
                            ?.valid && $dataForm.radioExample3Inother?._touched
                            ? 'is-invalid'
                            : ''}"
                          id="radioExample4Inother"
                          name="radioExample4Inother"
                          bind:this={elementExample4Inother}
                          bind:value={cuestions.otherPMS}
                          on:keydown={(e) =>
                            e.key === "Enter"
                              ? cuestions.otherPMS &&
                                cuestions.otherPMS.toString().trim().length > 0
                                ? nextStep() && e.preventDefault()
                                : ($dataForm.radioExample3Inother._touched =
                                    true && e.preventDefault())
                              : undefined}
                          required
                          maxlength="50"
                          autocomplete="off"
                        />
                        {#if !$dataForm.radioExample4Inother?.touched || ($dataForm.radioExample4Inother?.touched && $dataForm.radioExample4Inother.valid)}
                          <div class="form-text">
                            Please press enter to continue
                          </div>
                        {:else}
                          <HintGroup for="radioExample4Inother">
                            <Hint
                              class="help-block help-block-left"
                              on="required"
                            >
                              <span class="field-invalid">
                                This field is required
                              </span>
                            </Hint>
                            <Hint
                              class="help-block help-block-left"
                              on="maxLength"
                              hideWhenRequired
                              let:value
                            >
                              <span class="field-invalid">
                                {"Maximo [X] caracteres".replace("[X]", value)}
                              </span>
                            </Hint>
                          </HintGroup>
                        {/if}
                      </div>
                    {/if}

                    <div class="col-6 form-check"></div>
                  </div>
                </div>
              </div>
            {/if}

            <!-- currentStep === 5 -->
            {#if cuestions.lastStep == 5 && ((currentStep == 1 && cuestions.steps[0] == "yes" && cuestions.steps[1]) || (currentStep >= 4 && currentStep <= 5))}
              <div
                class="card-footer text-center active"
                class:disabled={currentStep != 5}
              >
                <button
                  class="btn btn-primary"
                  type="submit"
                  disabled={disabledButton}
                >
                  Send
                </button>
              </div>
            {/if}
          </form>
        {/if}
      </div>
    </div>
  </div>
</section>

<!-- {#if $activeRoute.path && $activeRoute.path.includes("/survey/camping")}
{/if} -->

<style>
  /* :global(body) {
    overflow: hidden;
    height: 100vh;
  } */

  /* section {
    background-color: #f4f4f4;
  } */
  .row {
    margin-left: 0;
    margin-right: 0;
  }
  .card-title {
    color: var(--dark-blue-2-f-4050, var(--Blue-2F4050, #2f4050));
    /* H1 */
    font-family: "Open Sans";
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.875rem; /* 125% */
  }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-bottom: 1px solid gray;
    outline: 0;
    border-radius: 0;
    box-shadow: none;
  }

  .questions {
    overflow: hidden;
    color: var(--dark-blue-2-f-4050, var(--Blue-2F4050, #2f4050));
    text-overflow: ellipsis;
    white-space: nowrap;
    /* H2 */
    font-family: "Open Sans";
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.875rem; /* 125% */
  }

  .radioExample01 {
    color: var(--dark-blue-2-f-4050, var(--Blue-2F4050, #2f4050));
    text-align: center;
    font-family: "Material Icons";
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    width: 1.25rem;
    height: 1.25rem;
    line-height: normal;
  }
  .title-survey {
    color: var(--dark-blue-2-f-4050, var(--Blue-2F4050, #2f4050));
    font-family: "Open Sans";
    font-size: 3rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
  }

  .form-control {
    display: block;
    width: 100%;
    /* padding: 0.375rem 0.75rem; */
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border-bottom: 1px solid gray;
    border-radius: 0;
    transition:
      border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
    border-top: transparent;
    border-left: transparent;
    border-right: transparent;
    padding: 0;
  }
  .card {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: transparent;
    border-radius: 0.25rem;
  }

  .card-header {
    padding: 0;
    margin-bottom: 3rem;
    background-color: white;
    border-bottom: transparent;
  }

  .vh-100 {
    height: 100vh !important;
  }

  .card-body {
    padding: 0 !important;
    pointer-events: none;
  }

  .form-check {
    position: relative;
    display: block;
    padding-left: 0;
  }

  .card-body,
  .card-header,
  .card-footer {
    display: none;
    opacity: 0.5;
    transition: opacity 0.3s ease-in-out;
  }

  .card-header,
  .card-body.active,
  .card-footer.active {
    display: block;
    opacity: 1;
    border-top: transparent !important;
    pointer-events: auto;
  }

  .card-body.active.disabled,
  .card-header.disabled,
  .card-footer.disabled {
    opacity: 0.2;
    pointer-events: none;
  }

  .active {
    animation: fadeInUp 0.5s ease-in-out;
    margin-top: 5rem;
  }

  #html-spinner {
    width: 40px;
    height: 40px;
    border: 4px solid #16328d;
    border-top: 4px solid white;
    border-radius: 50%;
  }
  input[type="radio"]::before {
    content: "";
    color: green;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    transform: scale(0.5);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em green;
  }
  .option-radio-button {
    display: flex;
    padding: 0.5rem;
    align-items: center;
    gap: 0.625rem;
    flex: 1 0 0;
    border-radius: 0.5rem;
    border: 1px solid var(--light-grey-3-c-3-c-3-c-3, #c3c3c3);
  }
  .option-radio-button-Pms {
    display: flex;
    padding: 0.5rem;
    align-items: center;
    gap: 0.625rem;
    flex: 1 0 0;
    margin-top: 1rem;
    border-radius: 0.5rem;
    border: 1px solid var(--light-grey-3-c-3-c-3-c-3, #c3c3c3);
  }
  .border-active {
    display: flex;
    padding: 0.5rem;
    align-items: center;
    gap: 0.625rem;
    accent-color: #23a58b;
    flex: 1 0 0;
    border-radius: 0.5rem;
    border: 1px solid var(--green-1-ab-394, #1ab394);
  }

  @keyframes fadeInUp {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0%);
    }
  }

  :global(.header-white.sticky-bar) {
    background: transparent !important;
  }

  .btn-primary {
    background-color: #1ab394;
  }

  .custom-select.is-invalid,
  .form-control.is-invalid,
  .was-validated .custom-select:invalid,
  .was-validated .form-control:invalid {
    border-color: #ff4855;
  }

  .field-invalid {
    color: #ff4855;
  }
</style>
