<script>
  import { _, locale } from "svelte-i18n";

  const goTo = (idSection) =>
    window.scrollTo({
      top: document.getElementById(idSection).offsetTop,
      behavior: "smooth",
    });
</script>

<section
  id="home"
  class="slider-area slider-height-one-page position-relative"
  data-background="/img/bg/bg2.png"
  style="background: linear-gradient(0deg, #FFF 0%, #CCF5F4 105.58%);;"
>
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-6 d-flex align-items-center">
        <div class="topTextHeroOnePage" style="margin-top: 15rem;">
          <h1
            class="wow fadeInDown"
            style="font-size: clamp(1.6rem, 3.4vw, 4rem); "
            data-wow-delay=".3s"
          >
            <strong>
              {$_("You did it! Now how do you maintain it?")}
            </strong>
          </h1>

          <div class="col-sm-12 col-xl-6 offset-xl-1 col-lg-7 offst-lg-1">
            <div
              class="slider-img-2 position-relative d-sm-block d-md-none mt-40 mb-40"
            >
              <img
                class="wow fadeInDown"
                data-wow-delay=".6s"
                src="/img/slider/hero-one-page2.png"
                alt={$_(
                  "A multi-device platform for the reception and the guest",
                )}
              />
            </div>
          </div>
        </div>
      </div>
      <div class="d-none d-md-flex col-md-6 align-items-center">
        <div
          class="slider-img-2 position-relative"
          style="
        flex-shrink: 0;"
        >
          <img
            class="wow fadeInDown"
            data-wow-delay=".6s"
            src="/img/slider/hero-one-page2.png"
            alt={$_("A multi-device platform for the reception and the guest")}
            style="width: 100%;"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="callBottom styleButton">
    <div style="margin-bottom:1rem">
      <p class="d-none d-md-inline-block">
        <strong>
          {$_("Are you ready to enhance your guests' experience?")}
        </strong>
      </p>
    </div>
    <div
      class="slider-text-2-book-demo d-sm-inline-block"
      style="margin-bottom: 1rem;"
    >
      <a
        class="btn styleBtn"
        style="display: flex;"
        data-wow-delay=".6s"
        href="tel:+584125785400"
      >
        <i class="material-icons" style="margin-right: 1rem "> phone </i>{$_(
          "Call us",
        )}
      </a>
    </div>
  </div>
</section>

<style>
  .styleButton {
    display: flex;
    justify-content: space-around;
    border-top: 1px solid #e3e3e3;
    position: fixed;
    align-items: flex-end;
    z-index: 3;
    width: 100%;
    background: white;
    bottom: 0px;
    padding: 1rem 1rem;
  }

  a:focus,
  a:hover,
  .portfolio-cat a:hover,
  .footer -menu li a:hover {
    color: #fafafa;
    text-decoration: none;
  }
  .styleBtn:hover {
    box-shadow: 0 16px 32px #1ab39466;
  }

  .btn:hover {
    background: #1ab394;
    color: #fff;
  }
</style>
