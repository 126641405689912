<script>
    import { _ } from "svelte-i18n";
    import { activeRoute, redirect } from "../pager";

    const goToNavigation = (url) => {
        if (
            window.location.pathname.includes("/terms") ||
            window.location.pathname.includes("/privacy-policy") ||
            window.location.pathname.includes("/pricing") ||
            window.location.pathname.includes("/support-cloudbeds")
        ) {
            window.location.href = "/" + url;
        } else {
            window.location.href = url;
        }
    };
</script>

<footer class="footerStyle">
    <div class="container">
        {#if $activeRoute.path && $activeRoute.path.includes("/one-page/")}
            <div class="row">
                <div class="d-none d-md-block col-md-6 col-lg-6 col-xxl-6">
                    <div class="copyright text-center">
                        <p class="colorFooter" style="margin-bottom: 0">
                            Powered By:
                            <!-- svelte-ignore security-anchor-rel-noreferrer -->
                            <a href="https://norelian.com" target="_blank">
                                <img
                                    src="/img/logo/norelian.png"
                                    height="40px"
                                    style="margin-left: 10px;"
                                    alt="Norelian LLC"
                                    rel="noopener noreferrer"
                                />
                            </a>
                        </p>
                    </div>
                </div>
                <div class="col-12 d-block d-md-none">
                    <div class="copyright text-center">
                        <p class="colorFooter" style="margin-bottom: 0">
                            Powered By:
                            <!-- svelte-ignore security-anchor-rel-noreferrer -->
                            <a href="https://norelian.com" target="_blank">
                                <img
                                    src="/img/logo/norelian.png"
                                    height="40px"
                                    style="margin-left: 10px;"
                                    alt="Norelian LLC"
                                    rel="noopener noreferrer"
                                />
                            </a>
                        </p>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-6 col-xxl-6">
                    <div class="copyright text-center">
                        <p class="colorFooter">
                            &copy; {new Date().getFullYear()}
                            <span style="color: var(--1-ab-394, #1AB394);"
                                >GuestHub</span
                            >
                        </p>
                    </div>
                </div>
            </div>
        {:else if $activeRoute.path && !$activeRoute.path.includes("/survey/camping")}
            <div class="row">
                <div class="col-12 col-md-2 col-lg-2 col-xxl-2">
                    <div class="copyright text-center">
                        <p class="colorFooter">{$_("All Rights Reserved")}</p>
                    </div>
                </div>
                <div class="col-12 col-md-2 col-lg-2 col-xxl-2">
                    <div class="copyright text-center">
                        <a class="colorFooter" href="/terms"
                            >{$_("Terms & Conditions")}</a
                        >
                    </div>
                </div>

                <div class="d-none d-md-block col-md-4 col-lg-4 col-xxl-4">
                    <div class="copyright text-center">
                        <p class="colorFooter" style="margin-bottom: 0">
                            {$_("Powered By:")}
                            <!-- svelte-ignore security-anchor-rel-noreferrer -->
                            <a href="https://norelian.com" target="_blank">
                                <img
                                    src="/img/logo/norelian.png"
                                    height="40px"
                                    style="margin-left: 10px;"
                                    alt="Norelian LLC"
                                    rel="noopener noreferrer"
                                />
                            </a>
                        </p>
                    </div>
                </div>

                <div
                    class="col-12 col-md-2 col-lg-2 col-xxl-2 footerResponsive"
                >
                    <div class="copyright text-center">
                        <a class="colorFooter" href="/privacy-policy"
                            >{$_("Privacy Policy")}</a
                        >
                    </div>
                </div>
                <div class="col-12 col-md-2 col-lg-2 col-xxl-2">
                    <div class="copyright text-center">
                        <p class="colorFooter">
                            &copy; {new Date().getFullYear()} GuestHub
                        </p>
                    </div>
                </div>

                <div class="col-12 d-block d-md-none">
                    <div class="copyright text-center">
                        <p class="colorFooter" style="margin-bottom: 0">
                            {$_("Powered By:")}
                            <!-- svelte-ignore security-anchor-rel-noreferrer -->
                            <a href="https://norelian.com" target="_blank">
                                <img
                                    src="/img/logo/norelian.png"
                                    height="40px"
                                    style="margin-left: 10px;"
                                    alt="Norelian LLC"
                                    rel="noopener noreferrer"
                                />
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        {/if}
    </div>
</footer>

{#if $activeRoute.path && $activeRoute.path.includes("/one-page/")}
    <style>
        body {
            padding-bottom: 6rem;
        }
    </style>
{/if}
