<script>
  import { locale, _ } from "svelte-i18n";
  import { activeRoute, redirect } from "../pager";

  export let stickyHeader;

  const goTo = (idSection) =>
    window.scrollTo({
      top: document.getElementById(idSection).offsetTop,
      behavior: "smooth",
    });

  const changeLanguage = (lang) => {
    locale.set(lang);
    localStorage.setItem("lang", lang);
  };

  const goToNavigation = (url) => {
    if (
      window.location.pathname.includes("/terms") ||
      window.location.pathname.includes("/privacy-policy") ||
      window.location.pathname.includes("/pricing") ||
      window.location.pathname.includes("/support-cloudbeds")
    ) {
      window.location.href = "/" + url;
    } else {
      window.location.href = url;
    }
  };
</script>

<header>
  <div
    id="header-sticky"
    data-sticky={stickyHeader}
    class="header-area header-white {stickyHeader
      ? 'transparent-header sticky-bar'
      : 'transparent-header'} pt-20 pb-20"
  >
    <nav class="navbar navbar-expand-lg">
      {#if $activeRoute.path && ($activeRoute.path.includes("/one-page/") || $activeRoute.path.includes("/survey/camping"))}
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <!-- svelte-ignore a11y-missing-attribute -->
        <a class="navbar-brand" href="#top">
          <div class="logo navbar-menu-one-page" />
        </a>
      {:else}
        <a class="navbar-brand" href="/#top">
          <div
            class="logo {$activeRoute.path &&
            $activeRoute.path.includes('/one-page/')
              ? 'navbar-menu-one-page'
              : ''}"
          />
        </a>
      {/if}

      {#if $activeRoute.path && !$activeRoute.path.includes("/one-page/") && !$activeRoute.path.includes("/survey/camping")}
        <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
          <a
            class="dropdown-item"
            href="#!"
            on:click|preventDefault={() => changeLanguage("en")}
            >{$_("English")}</a
          >
          <div class="line-separate-blablalba">
            <span />
          </div>
          <a
            class="dropdown-item"
            href="#!"
            on:click|preventDefault={() => changeLanguage("es")}
            >{$_("Spanish")}</a
          >
        </div>

        <button
          class="navbar-toggler"
          type="button"
          href="#!"
          id="navbarDropdownMenuLink"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <i class="material-icons iconLang"> public </i>
          <span class="lang">{$_("Es")}</span>
        </button>

        <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
          <a
            class="dropdown-item"
            href="#!"
            on:click|preventDefault={() => changeLanguage("en")}
            >{$_("English")}</a
          >
          <div class="line-separate-blablalba">
            <span />
          </div>
          <a
            class="dropdown-item"
            href="#!"
            on:click|preventDefault={() => changeLanguage("es")}
            >{$_("Spanish")}</a
          >
        </div>
      {/if}

      {#if $activeRoute.path && !$activeRoute.path.includes("/survey/camping") && $activeRoute.path != "/license-agreement" && $activeRoute.path != "/privacy-policy"}
        <button
          class="navbar-toggler {$activeRoute.path &&
          $activeRoute.path.includes('/one-page/')
            ? 'navbar-toggler-white'
            : ''}"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i class="material-icons"> menu </i>
        </button>
      {/if}

      <div class="collapse navbar-collapse" id="navbarNavDropdown">
        <div
          class="main-menu ml-auto {$activeRoute.path &&
          $activeRoute.path.includes('/one-page/')
            ? 'main-menu-white'
            : ''}"
        >
          <nav id="mobile-menu">
            <ul
              class={$activeRoute.path &&
              $activeRoute.path.includes("/one-page/")
                ? "navbar-menu-one-page"
                : ""}
            >
              {#if $activeRoute.path != "/license-agreement" && $activeRoute.path != "/privacy-policy"}
                {#if $activeRoute.path && $activeRoute.path.includes("/one-page/")}
                  <li>
                    <a
                      class="text-uppercase"
                      href="#!"
                      on:click|preventDefault={() =>
                        goToNavigation("#ReviewFiltering")}
                      >{$_("Review Filtering")}</a
                    >
                  </li>
                  <li>
                    <a
                      class="text-uppercase"
                      href="#!"
                      on:click|preventDefault={() =>
                        goToNavigation("#ParetoPrinciple")}
                      >{$_("Pareto Principle")}</a
                    >
                  </li>
                  <li>
                    <a
                      class="text-uppercase"
                      href="#!"
                      on:click|preventDefault={() =>
                        goToNavigation("#OwnYourGuests")}
                      >{$_("Own Your Guests")}</a
                    >
                  </li>

                  <!-- <li>
                    <a href="/pricing">{$_("Pricing")}</a>
                  </li> -->
                {:else if $activeRoute.path && !$activeRoute.path.includes("/survey/camping")}
                  <li>
                    <a
                      href="#!"
                      on:click|preventDefault={() =>
                        goToNavigation("#benefits")}>{$_("Benefits")}</a
                    >
                  </li>
                  <li>
                    <a
                      href="#!"
                      on:click|preventDefault={() => goToNavigation("#product")}
                      >{$_("Products")}</a
                    >
                  </li>
                  <li>
                    <a
                      href="#!"
                      on:click|preventDefault={() => goToNavigation("#contact")}
                      >{$_("Contact")}</a
                    >
                  </li>

                  <li>
                    {#if $activeRoute.path == "/pricing"}
                      <!-- svelte-ignore a11y-invalid-attribute -->
                      <a href="javascript:;">{$_("Pricing")}</a>
                    {:else}
                      <a href="/pricing">{$_("Pricing")}</a>
                    {/if}
                  </li>
                {/if}
              {/if}

              {#if $activeRoute.path && !$activeRoute.path.includes("/one-page/") && !$activeRoute.path.includes("/survey/camping")}
                <li class="dropdown langHidden">
                  <a
                    class="nav-link dropdown-toggle"
                    href="#!"
                    id="navbarDropdownMenuLink"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {#if $locale == "es"}
                      {$_("Spanish")}
                    {:else}
                      {$_("English")}
                    {/if}
                  </a>
                  <div
                    class="dropdown-menu"
                    aria-labelledby="navbarDropdownMenuLink"
                  >
                    <a
                      class="dropdown-item"
                      href="#!"
                      on:click|preventDefault={() => changeLanguage("en")}
                      >{$_("English")}</a
                    >
                    <div class="line-separate-blablalba">
                      <span />
                    </div>
                    <a
                      class="dropdown-item"
                      href="#!"
                      on:click|preventDefault={() => changeLanguage("es")}
                      >{$_("Spanish")}</a
                    >
                  </div>
                </li>
              {/if}

              {#if $activeRoute.path != "/license-agreement" && $activeRoute.path != "/privacy-policy" && $activeRoute.path && !$activeRoute.path.includes("/one-page/") && !$activeRoute.path.includes("/survey/camping")}
                <li>
                  <a
                    style="width:100%; border-radius:8px ; color:white; padding: 16px 28px;"
                    href="#contact"
                    on:click={() => goTo("contact")}
                    class="btn btn-outline-success">{$_("Book demo")}</a
                  >
                </li>
              {/if}
            </ul>
          </nav>
        </div>
      </div>
    </nav>
  </div>
</header>
