<script>
  import { _ } from "svelte-i18n";
  import { activeRoute, redirect } from "./pager";
  import Router from "./Router.svelte";
  import {
    addMessages,
    init,
    getLocaleFromNavigator,
    locale,
  } from "svelte-i18n";

  import en from "./assets/en.json";
  import es from "./assets/es.json";
  import { GoogleAnalytics } from "@beyonk/svelte-google-analytics";

  addMessages("en", en);
  addMessages("es", es);

  init({
    fallbackLocale: "en",
    initialLocale: getLocaleFromNavigator(),
  });

  if (localStorage.getItem("lang")) {
    locale.set(localStorage.getItem("lang"));
  } else {
    locale.set(getLocaleFromNavigator().split("-")[0]);
    localStorage.setItem("lang", getLocaleFromNavigator().split("-")[0]);
  }

  import HeaderComponent from "./header/component.svelte";
  import FooterComponent from "./footer/component.svelte";

  let stickyHeader = false;
  let params = {};
  activeRoute.subscribe((active) => {
    if (active.path == "**") {
      redirect("/");
      return;
    }
    params = Object.assign({}, active.params);
    stickyHeader = false;
    /*window.scrollTo({
      top: 0,
      behavior: "smooth",
    });*/
  });

  let acceptCookies = document.cookie.replace(
    /(?:(?:^|.*;\s*)iAcceptCookies\s*\=\s*([^;]*).*$)|^.*$/,
    "$1",
  )
    ? document.cookie.replace(
        /(?:(?:^|.*;\s*)iAcceptCookies\s*\=\s*([^;]*).*$)|^.*$/,
        "$1",
      )
    : null;

  const acceptCookiesConfirm = () => {
    acceptCookies = "yes";
    document.cookie = "iAcceptCookies=yes;";
  };
  const rejectCookiesConfirm = () => {
    acceptCookies = "no";
    const cookies = document.cookie.split(";");

    for (var i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }

    document.cookie = "iAcceptCookies=no;";
  };

  const resetConfigCookies = () => {
    acceptCookies = null;
    const cookies = document.cookie.split(";");

    for (var i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
  };
  if (acceptCookies && acceptCookies == "no") {
    rejectCookiesConfirm();
  }
</script>

<svelte:head>
  <link rel="canonical" href={window.location.origin} />

  <meta
    name="description"
    content={$_(
      "A unified hub to manage and improve your guest experience from reservation to review™",
    )}
  />
  <meta name="keywords" content={$_("keyworks-general")} />

  <meta
    property="og:description"
    content={$_(
      "A unified hub to manage and improve your guest experience from reservation to review™",
    )}
  />
  <meta
    property="og:image"
    content="{window.location.origin}/img/seo-cover-1.jpg"
  />
</svelte:head>

{#if window.location.hostname.includes("guesthub.io")}
  <GoogleAnalytics properties={["4334176688"]} enabled={true} />
{/if}

<HeaderComponent bind:stickyHeader />

<main>
  <Router {params} bind:stickyHeader />
</main>



{#if $activeRoute.path != "/survey/camping"}
  <FooterComponent />
{/if}
