<script>
    import { _, locale } from "svelte-i18n";
    let viewMore = false;

    const goTo = (idSection) =>
        window.scrollTo({
            top: document.getElementById(idSection).offsetTop,
            behavior: "smooth",
        });
</script>

<div class="container-fluid">
    <div class="row">
        <div class="col-md-6 d-none d-md-block ">
            <div class="features-img nav overflow product-tabs hideScroll justify-content-center">
                <img
                    class="wow fadeInUp imgDesk responsiveSm"
                    data-wow-delay=".6s"
                    src="/img/benefits/benefits-2-{$locale}.png"
                    alt="{$_("Configuration of automatic emails and how they reach the guest")}"
                    style="width: 80%;"
                />
            </div>
        </div>
        <div class="col-md-5">
            <div class="benefits-area-two benefits-area-two-backround">
                <div class="section-titles testimonail area txtBenefist2">
                    <h1 class="wow fadeInUp " data-wow-delay=".6s">
                        {$_("Drive incremental revenue")}
                    </h1>

                    <div class="col-sm-12 d-sm-block d-md-none mt-20 mb-20">
                        <div class="features-img nav overflow product-tabs hideScroll justify-content-center">
                            <img
                                class="wow fadeInUp imgDesk  benefist2Md responsiveSm benefist1Sm"
                                data-wow-delay=".6s"
                                src="/img/benefits/benefits-2-{$locale}.png"
                                alt="{$_("Configuration of automatic emails and how they reach the guest")}"
                                style="width: 80%;"
                            />
                        </div>
                    </div>

                    <p class="wow fadeInUp " data-wow-delay=".6s">
                        {$_("Open a line of communication prior to your guest’s arrival and increase ancillary revenue during their stay by sending timely and relevant promotional messages.")}
                    </p>

                    {#if viewMore}
                        <p style="margin: 15px 0 15px 0;">
                            {$_("An easy and seamless way to increase F&B sales without asking your guests to download any applications.")}
                        </p>
                    {:else}
                        <p style="margin: 15px 0 15px 0;" />
                    {/if}

                    <a
                        style="color: #1AB394;"
                        class="wow fadeInUp "
                        data-wow-delay=".6s"
                        href="#!"
                        on:click={() => (viewMore = !viewMore)}
                    >
                        {$_(viewMore ? "Hide" : "Learn more")}
                        <i style="position: absolute;" class="material-icons">
                            {viewMore ? "expand_less" : "expand_more"}
                        </i>
                    </a>
                    <div class="mt-40 smVersion">
                        <a
                            class="btn wow fadeInUp  styleBtn"
                            data-wow-delay=".6s"
                            href="#!"
                            on:click|preventDefault={() => goTo("contact")}
                            >{$_("Book demo")}</a
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
