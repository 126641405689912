<script src="js/myscript.js">
  import { _, locale } from "svelte-i18n";
  import { query_selector_all } from "svelte/internal";
  import ModalVideoComponent from "../modal-video.component.svelte";

  let modalOpenVideo = false;
  const configurationVideo = {
    title: "How the filtering works? ",
    videoUrl: "https://www.youtube.com/embed/yNENVZFHutQ?si=nqJ-FHEUe6gKAFbc",
  };
</script>

<div class="container">
  <div class="row">
    <div class="col-12 col-md-6 d-flex align-items-center">
      <div class="section-titles testimonail area txtBenefist2">
        <div class="section-titles mb-35">
          <h1 class="wow fadeInUp" data-wow-delay=".6s">
            {$_("Review Filtering")}
          </h1>
          <div
            class="col-sm-12 d-sm-block d-md-none wow fadeInUp mt-20 mb-20"
            data-wow-delay=".6s"
          >
            <div class="features-img">
              <img
                class="wow fadeInUp benefisSizeIgm"
                data-wow-delay=".6s"
                src="/img/benefits/filtering.webp"
                alt={$_(
                  "The guest making requests from the comfort of his or her phone",
                )}
              />
            </div>
          </div>
          <p class="wow fadeInUp" data-wow-delay=".6s">
            {$_(
              "Incentivize your guests to share their positive experience online and create a guest recovery process to establish direct contact with guests who had neutral or negative experience",
            )}
          </p>
          <!-- svelte-ignore a11y-invalid-attribute -->
          <a
            on:click|preventDefault={() => (modalOpenVideo = true)}
            href="javascript:;"
            data-wow-delay=".6s"
            class="mt-40 modal-video-size wow fadeInUp"
          >
            <!-- svelte-ignore a11y-missing-attribute -->
            <i class="material-icons buttomPlay"> play_circle</i>
            <p class="text-video">How the filtering works?</p>
          </a>
        </div>
      </div>
    </div>
    <div class="col-md-6 d-none d-md-flex align-items-center">
      <div class="features-img">
        <img
          class="wow fadeInUp benefits-area-one"
          data-wow-delay=".6s"
          src="/img/benefits/filtering.webp"
          alt={$_(
            "The guest making requests from the comfort of his or her phone",
          )}
          style="width: 100%;"
        />
      </div>
    </div>
  </div>
</div>

{#if modalOpenVideo}
  <ModalVideoComponent
    config={configurationVideo}
    on:close={() => (modalOpenVideo = false)}
  ></ModalVideoComponent>
{/if}

<style>
  .modal-video-size {
    text-align: left;
    display: flex;
    cursor: pointer;
  }
  .buttomPlay {
    color: var(--green-1-ab-394, var(--Green-1AB394, #1ab394));
    font-size: 2.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.625rem;
  }
  .text-video {
    color: var(--green-1-ab-394, var(--Green-1AB394, #1ab394));
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.625rem; /* 162.5% */
  }
  .text-video:hover {
    text-decoration-line: underline;
  }
  .modal-body {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 0rem 1rem 1rem 1rem;
  }
  .modal-content {
    position: relative;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 1rem;
    outline: 0;
  }
  .modal-dialog {
    max-width: 100%;
    width: 800px;
  }
  @media (max-width: 767.98px) {
    .modal-dialog {
      width: auto !important;
      max-width: 100% !important;
    }
  }
</style>
