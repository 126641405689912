<script>
  import { _ } from "svelte-i18n";
  import { onMount, onDestroy } from "svelte";
  import { ga } from "@beyonk/svelte-google-analytics";
  import {
    useForm,
    required,
    validators,
    email,
    maxLength,
    minLength,
    Hint,
    HintGroup,
  } from "svelte-use-form";
  import { environments } from "../assets/environments";

  const dataForm = useForm();
  let disabledButton = false;
  let recaptchaToken = null;
  let sendingEmail = false;
  let errorSending = false;
  let formContact = {
    propertyName: "",
    numberOfRooms: "",
    name: "",
    phone: "",
    email: "",
  };

  const recaptchaCheck = (event) => {
    recaptchaToken = event;
  };

  const recaptchaError = () => {
    recaptchaToken = null;
  };

  onMount(() => {
    window.recaptchaCheck = recaptchaCheck;
    window.recaptchaError = recaptchaError;
  });

  onDestroy(() => {
    window.recaptchaCheck = null;
    window.recaptchaError = null;
  });

  const handlerSubmitForm = (e) => {
    sendingEmail = false;
    errorSending = false;
    if (recaptchaToken) {
      disabledButton = true;

      fetch(`${environments.api}/contactus`, {
        method: "POST",
        body: JSON.stringify(
          Object.assign({}, formContact, {
            "g-recaptcha-response": "doaqoaddola",
          }),
        ),
        headers: {
          "content-type": "application/json",
        },
      })
        .then((response) => (response.ok ? true : Promise.reject(response)))
        .then((response) => {
          formContact = {
            propertyName: "",
            numberOfRooms: "",
            name: "",
            phone: "",
            email: "",
          };
          disabledButton = false;
          sendingEmail = true;
          recaptchaToken = null;
          ga.addEvent("boton_contactenos", true);
        })
        .catch((err) => {
          console.error(err);
          disabledButton = false;
          errorSending = true;
          recaptchaToken = null;
        });
    } else {
      alert("Recatpcha invalid");
    }
  };
</script>

<svelte:head>
  <script src="https://www.google.com/recaptcha/api.js" async defer></script>
</svelte:head>

<section id="contact">
  <section class="features-area pt-120 pb-160 pt-sm-80 pb-sm-80 pts-40">
    <div class="container">
      <div class="row">
        <div class="col-sm-12 pt-40 pb-40">
          <div class="wow fadeInUp" data-wow-delay=".6s">
            <div class="row">
              {#if sendingEmail}
                <div class="col-12">
                  <div style="text-align: center;" class="container">
                    <div style="margin-bottom: 15px;">
                      <img src="/img/icon/checkPositive.png" alt="" />
                    </div>
                    <div>
                      <h3 style="color: black;">
                        {$_("Your contact request was sent")}
                      </h3>
                      <p>{$_("We will contact you as soon as possible")}</p>
                    </div>
                  </div>
                  <!-- {$_("Contact sent!")}. -->
                </div>
              {:else}
                <div class="col-xl-12 text-center">
                  <div class="section-titles mb-60">
                    <h1>
                      {$_("Are you ready to improve your Guest’s experience?")}
                    </h1>
                  </div>
                </div>
                <form
                  use:dataForm
                  class="col-12 offset-md-1 col-md-10 offset-lg-3 col-lg-6 text-center"
                  on:submit|preventDefault={handlerSubmitForm}
                >
                  <!--
                    {#if $dataForm.propertyName?.touched && $dataForm.propertyName?.errors.required}
                      {#if $dataForm.propertyName.errors.required}
                        Debes selecciónar un país.
                      {/if}
                    {/if}
                    <br /-->
                  <div class="row">
                    <div class="col-12 col-md-6 control">
                      <input
                        use:validators={[required, minLength(1), maxLength(30)]}
                        type="text"
                        class="form-control"
                        placeholder={$_("Property name")}
                        autocomplete="off"
                        name="propertyName"
                        bind:value={formContact.propertyName}
                        required
                        minlength="1"
                        maxlength="30"
                      />
                      <HintGroup
                        for="propertyName"
                        class="help-block help-block-left field-invalid"
                      >
                        <Hint class="required" on="required">
                          {$_("This field is required")}
                        </Hint>
                        <Hint on="minLength" hideWhenRequired let:value>
                          Minimo de caracteres: {value}
                        </Hint>
                        <Hint on="maxLength" hideWhen="minLength" let:value>
                          Máximo de caracteres: {value}
                        </Hint>
                      </HintGroup>
                    </div>
                    <div class="col-12 col-md-6 control">
                      <input
                        use:validators={[required, minLength(1), maxLength(4)]}
                        type="text"
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                        class="form-control"
                        placeholder={$_("Number of rooms")}
                        autocomplete="off"
                        name="numberOfRooms"
                        bind:value={formContact.numberOfRooms}
                        minlength="1"
                        maxlength="4"
                      />
                      <HintGroup
                        for="numberOfRooms"
                        class="help-block help-block-left field-invalid"
                      >
                        <Hint class="required" on="required">
                          {$_("This field is required")}
                        </Hint>
                        <Hint on="minLength" hideWhenRequired let:value>
                          Minimo de caracteres: {value}
                        </Hint>
                        <Hint on="maxLength" hideWhen="minLength" let:value>
                          Máximo de caracteres: {value}
                        </Hint>
                      </HintGroup>
                    </div>
                    <div class="col-12 control">
                      <input
                        use:validators={[required, minLength(1), maxLength(30)]}
                        type="text"
                        class="form-control"
                        placeholder={$_("Contact name")}
                        autocomplete="off"
                        name="name"
                        bind:value={formContact.name}
                        required
                        minlength="1"
                        maxlength="30"
                      />
                      <HintGroup
                        for="name"
                        class="help-block help-block-left field-invalid"
                      >
                        <Hint class="required" on="required">
                          {$_("This field is required")}
                        </Hint>
                        <Hint on="minLength" hideWhenRequired let:value>
                          Minimo de caracteres: {value}
                        </Hint>
                        <Hint on="maxLength" hideWhen="minLength" let:value>
                          Máximo de caracteres: {value}
                        </Hint>
                      </HintGroup>
                    </div>

                    <div class="col-12 col-md-6 control">
                      <input
                        type="tel"
                        class="form-control"
                        placeholder={$_("Phone number (optional)")}
                        autocomplete="off"
                        name="phone"
                        bind:value={formContact.phone}
                        minlength="1"
                        maxlength="30"
                      />
                    </div>

                    <div class="col-12 col-md-6 control">
                      <input
                        use:validators={[required, email]}
                        type="email"
                        class="form-control"
                        placeholder={$_("Email")}
                        autocomplete="off"
                        name="email"
                        bind:value={formContact.email}
                        minlength="1"
                      />
                      <HintGroup
                        for="email"
                        class="help-block help-block-left field-invalid"
                      >
                        <Hint class="required" on="required">
                          {$_("This field is required")}
                        </Hint>
                        <Hint class="required" on="email" hideWhenRequired>
                          {$_("This must be a valid email")}
                        </Hint>
                      </HintGroup>
                    </div>

                    <div class="col-s12 recapchat">
                      <div
                        class="g-recaptcha"
                        data-sitekey={environments.googleRecaptchaSiteKey}
                        data-callback="recaptchaCheck"
                        data-error-callback="recaptchaError"
                        data-expired-callback="recaptchaError"
                      />
                    </div>

                    <div class="col-12">
                      <button
                        type="submit"
                        class="btn btn-primary styleBtn"
                        disabled={!$dataForm.valid ||
                          disabledButton ||
                          !recaptchaToken}>{$_("Contact us!")}</button
                      >
                    </div>

                    {#if errorSending}
                      <div class="col-12">
                        <div
                          style="margin-bottom: 15px; display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-top: 30px;"
                        >
                          <img
                            style="width: 25px;"
                            src=".\img\icon\cancel.png"
                            alt=""
                          />
                          <div>
                            <p
                              style="margin-top: revert; margin-left: 15px; color:#ff4855"
                            >
                              <strong>{$_("There was an error")}</strong>
                              {$_("try again later")}
                            </p>
                          </div>
                        </div>
                      </div>
                    {/if}
                  </div>
                </form>
              {/if}
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</section>
