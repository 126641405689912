<script>
    import { onMount, createEventDispatcher } from "svelte";

    export let config;
    let modalBackdrop;

    onMount(() => {
        modalBackdrop.addEventListener("click", (e) =>
            event.target.contains(modalBackdrop) ? closeModal(e) : null,
        );
    });

    const closeModal = (e) => dispatch("close", true);

    const dispatch = createEventDispatcher();
</script>

<!-- modal-dialog-scrollable -->
<div class="modal-backdrop fade show" />
<div bind:this={modalBackdrop} class="modal fade show" role="dialog">
    <div class="modal-content">
        <!-- Modal Header -->
        <div class="modal-header">
            <h5 class="modal-title">
                {config.title}
            </h5>
            <button type="button" class="close" on:click={closeModal}>
                <span class="material-icons">close</span>
            </button>
        </div>

        <!-- Modal Body -->
        <div class="modal-body">
            <!-- svelte-ignore a11y-missing-attribute -->
            <iframe
                class="video-tutorial"
                src={config.videoUrl}
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
            ></iframe>
        </div>
    </div>
</div>

<style>
    .btn-close {
        border: 0;
    }
    .modal {
        display: flex;
        position: fixed;
        z-index: 1050;
        padding: 1rem;
        overflow: hidden;
        align-items: center;
        justify-content: center;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .modal-content {
        overflow: hidden;
        background-clip: padding-box;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 1rem;
        max-width: 800px;
        /* Ajusta según necesidad */
        max-height: calc(100vh - 2rem);
        /* Evita que el modal toque los bordes de la ventana */
    }

    .modal-header,
    .modal-footer {
        padding: 0.5rem 1rem;
    }

    .modal-body {
        overflow-y: auto;
        padding: 0rem 1rem 1rem 1rem;
    }

    .video-tutorial {
        width: 100%;
        height: 500px;
    }

    /* Ajustes para dispositivos móviles */
    @media (max-width: 576px) {
        .video-tutorial {
            width: 100%;
            height: 250px;
        }

        /* .modal {
            align-items: flex-end;
             Alinea el modal al final en móviles 
        } */

        .modal-content {
            width: 100%;
            max-height: 100%;
            height: auto;
            bottom: 0;
            /* border-radius: 0; */
            /* Opcional: para bordes cuadrados en móviles */
        }

        .modal-body {
            max-height: calc(100vh - 122px);
            /* Ajusta según la altura del header y footer */
        }
    }
</style>
