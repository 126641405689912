<script>
    import { _, locale } from "svelte-i18n";

    export let stickyHeader;

    setTimeout(() => (stickyHeader = true), 100);
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });

    let steps = [
        {
            title: `Login to Guesthub and go to Guests section on the navigation sidebar`,
        },
        {
            title: `Select Cloudbeds PMS`,
        },
        {
            title: `Connect to your existing Cloudbeds account`,
        },
        {
            title: `Authorize Guesthub to access your Cloudbeds account`,
        },
        {
            title: `Cloudbeds integration widget is installed and you can edit or unsubscribe`,
        },
        {
            title: `By clicking on Edit button you can check and manage your guest list`,
        },
        {
            title: `If you need to disable the Cloudbeds integration you just have to click the Unsubscribe button`,
        },
    ];
</script>

<svelte:head>
    <title>{$_("Support Cloudbeds - GuestHub")}</title>
    <meta property="og:title" content={$_("Support Cloudbeds - GuestHub")} />
</svelte:head>

<section class="features-area pt-100 pb-120">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12" style="padding: 40px 0;">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="container">
                                <div
                                    class="row pt-100 pb-100 row-padding row-padding-2"
                                >
                                    <div class="col-xl-6 col-lg-6">
                                        <div class="single-features-area-down">
                                            <div
                                                class="section-titles mb-35 mt-40"
                                            >
                                                <h1
                                                    class="wow fadeInLeft"
                                                    data-wow-delay=".6s"
                                                >
                                                    Integrating Guesthub with
                                                    Cloudbeds
                                                </h1>
                                                <div
                                                    class="col-sm-12 d-sm-block d-lg-none"
                                                >
                                                    <div
                                                        class="features-img mb-30"
                                                    >
                                                        <img
                                                            class="wow fadeInRight"
                                                            data-wow-delay=".6s"
                                                            src="/img/integrations/cloudbedshero.png"
                                                            alt="Integrations Cloudbeds"
                                                            style="width: 100%;"
                                                        />
                                                    </div>
                                                </div>
                                                <p
                                                    class="wow fadeInLeft"
                                                    data-wow-delay=".6s"
                                                >
                                                    Follow these easy steps from
                                                    this guide to connect to
                                                    your existing Cloudbeds
                                                    account.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="col-xl-5 col-lg-5 d-none d-lg-block"
                                    >
                                        <div class="features-img mb-30">
                                            <img
                                                class="wow fadeInRight"
                                                data-wow-delay=".6s"
                                                src="/img/integrations/cloudbedshero.png"
                                                alt="Integrations Cloudbeds"
                                                style="width: 100%;"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    {#each steps as step, index}
                                        <div
                                            class="col-12 col-lg-1 center-block wow fadeInLeft"
                                            data-wow-delay=".6s"
                                        >
                                            <h3>{index + 1}</h3>
                                        </div>
                                        <div
                                            class="col-12 col-lg-11 center-block wow fadeInDown"
                                            data-wow-delay=".6s"
                                        >
                                            <h2>{step.title}</h2>
                                            <img
                                                class="wow fadeInDown"
                                                data-wow-delay=".6s"
                                                src="/img/support-cloubeds/{index +
                                                    1}-{$locale}.png"
                                                alt="Support Cloubeds step {index +
                                                    1} {$locale}"
                                                width="100%"
                                            />
                                        </div>
                                    {/each}

                                    <div
                                        class="col-12 mt-40 wow fadeInDown"
                                        data-wow-delay=".6s"
                                    >
                                        <h3>
                                            If you need any further assistance
                                            please contact support
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
