<script>
  import { _ } from "svelte-i18n";
  import InfoNumberOfRequests from "./info-number-of-requests.svelte";
  import InfoEscalations from "./info-escalations.svelte";
  import InfoBasicSupport from "./info-basic-support.svelte";
  import InfoEmailSupport from "./info-email-support.svelte";

  const goTo = (idSection) =>
    window.scrollTo({
      top: document.getElementById(idSection).offsetTop,
      behavior: "smooth",
    });

  const plans = [
    {
      title: "General",
      items: [
        {
          title: "Number of Users",
          starter: "3",
          full: "Unlimited",
          info: null,
        },
        {
          title: "3rd party integrations",
          starter: "-",
          full: "check",
          info: null,
        },
      ],
    },
    {
      title: "Requests",
      items: [
        {
          title: "Number of request",
          starter: "15",
          full: "Unlimited",
          info: "#infoNumberOfRequests",
        },
        {
          title: "History",
          starter: "15 days",
          full: "Unlimited",
          info: null,
        },
        {
          title: "Escalations",
          starter: "50/month",
          full: "Unlimited",
          info: "#infoEscalations",
        },
        {
          title: "Number of Rules",
          starter: "3",
          full: "Unlimited",
          info: null,
        },
      ],
    },
    {
      title: "Tasks",
      items: [
        {
          title: "Number of pending task",
          starter: "10",
          full: "Unlimited",
          info: null,
        },
             {
          title: "History",
          starter: "15 days",
          full: "Unlimited",
          info: null,
        },
      ],
    },
    {
      title: "Pickup",
      items: [
        {
          title: "Amount of shuttle",
          starter: "1",
          full: "Unlimited",
          info: null,
        },
        {
          title: "Shuttle Position Monitoring",
          starter: "check",
          full: "check",
          info: null,
        },
        {
          title: "Call Shuttle/Frontdesk GuestApp",
          starter: "-",
          full: "check",
          info: null,
        },
        {
          title: "Request Shuttle from GuestApp",
          starter: "-",
          full: "check",
          info: null,
        },
      ],
    },
    {
      title: "Check in",
      items: [
        {
          title: "Manage check in request at your property",
          starter: "-",
          full: "check",
          info: null,
        },
      ],
    },
    {
      title: "Comunications",
      items: [
        {
          title: "Amount of daily surveys",
          starter: "3",
          full: "Unlimited",
          info: null,
        },
        {
          title: "Amount of daily messages",
          starter: "3",
          full: "check",
          info: null,
        },
        {
          title: "Custom Messages",
          starter: "-",
          full: "check",
          info: null,
        },
      ],
    },
    {
      title: "Guest Items",
      items: [
        {
          title: "Package Tracking",
          starter: "30 days",
          full: "check",
          info: null,
        },
        {
          title: "Lost & Found",
          starter: "30 days",
          full: "check",
          info: null,
        },
        {
          title: "History",
          starter: "15 days",
          full: "Unlimited",
          info: null,
        },
      ],
    },
    {
      title: "Support",
      items: [
        {
          title: "Basic Support",
          starter: "check",
          full: "check",
          info: "#infoBasicSupport",
        },
        {
          title: "Email Support",
          starter: "-",
          full: "check",
          info: "#infoEmailSupport",
        },
        {
          title: "Phone Support",
          starter: "-",
          full: "check",
          info: null,
        },
        {
          title: "Video Support",
          starter: "$",
          full: "check",
          info: null,
        },
      ],
    },
    {
      title: "Analytics",
      items: [
        {
          title: "Dashboard",
          starter: "30 days",
          full: "Unlimited",
          info: null,
        },
        {
          title: "Communications",
          starter: "30 days",
          full: "Unlimited",
          info: null,
        },
        {
          title: "GuestApp",
          starter: "30 days",
          full: "Unlimited",
          info: null,
        },
        {
          title: "Custom report",
          starter: "1 report",
          full: "3 report",
          info: null,
        },
      ],
    },
  ];
</script>

<section id="pricing" class="features-area pt-120 pb-40 pt-sm-80 pb-sm-80">
  <div class="container-fluid">
    <div class="col-12 offset-lg-1 col-lg-10" style="padding: 0;">
      <div class="row" style="justify-content: center; ">
        <div class="col-md-6 d-none d-md-block styleTextPricing">
          <div
            class="features-img nav overflow product-tabs hideScroll justify-content-center"
          >
            <img
              class="wow fadeInUp imgDesk responsiveSm"
              data-wow-delay=".6s"
              src="/img\pricing\pricing-banner.png"
              alt={$_(
                "Configuration of automatic emails and how they reach the guest"
              )}
              style="width: 95%;"
            />
          </div>
        </div>
        <div class="col-md-5 styleImgPricing">
          <div class="section-pricing-titles testimonail area txtPricing-img">
            <h1 class="wow fadeInUp titlePricing" data-wow-delay=".6s">
              {$_("From tourist apartments to hotels with")}
              <strong>{$_("more than 500 rooms")}</strong>
            </h1>
            <p
              class="SubtitlePricing section-pricing-titles wow fadeInUp"
              data-wow-delay=".6s"
            >
              {$_(
                "Guesthub not only fits your hotel's needs, it also fits your budget."
              )}
            </p>
            <div class="col-sm-12 d-sm-block d-md-none mt-20 mb-20">
              <div
                class="features-img nav overflow product-tabs hideScroll justify-content-center"
              >
                <img
                  class="wow fadeInUp imgDesk benefist2Md responsivepricing1Sm pricing1Sm"
                  data-wow-delay=".6s"
                  src="/img\pricing\imagenes.svg"
                  alt={$_(
                    "Configuration of automatic emails and how they reach the guest"
                  )}
                  style="width: 80%;"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="wow fadeInDown pt-40 pb-20" data-wow-delay=".6s">
        <div class="section-titles-plans mb-30 text-center">
          <h1>
            {$_("Select the plan of your preference")}
          </h1>
        </div>
      </div>

      <div
        class="row mt-80 cards-plans"
        style="margin-left: 0; margin-right: 0;"
      >
        <div
          class="col-12 col-md-12 col-lg-4 spacingMd wow fadeInDown spacing"
          data-wow-delay=".2s"
        >
          <div class="card">
            <div class="card-body text-center">
              <h2 class="card-title">
                {$_("Starter")}
              </h2>
              <p class="card-text">
                {$_(
                  "Test all six Guesthub modules with basic functionality at no cost."
                )}
              </p>
            </div>
            <div class="card-footer text-center">
              <a
                href="#!"
                on:click|preventDefault={() => goTo("contact")}
                class="btn styleBtn"
                style=""
              >
                {$_("Get started")}
              </a>
            </div>
          </div>
        </div>

        <div
          class="col-12 col-md-12 col-lg-4 wow spacingMd fadeInDown"
          data-wow-delay=".4s"
        >
          <div class="card" style="background-color: #2F4050; ">
            <div class="card-body text-center">
              <h2 class="card-title" style="color: #FFFFFF;">
                {$_("Standard")}
              </h2>
              <p class="card-text" style="color: #FFFFFF;">
                {$_(
                  "Choose a single module with full functionality while testing out the additional 5 modules with basic functionality."
                )}
              </p>
            </div>
            <div class="card-footer text-center">
              <a
                href="#!"
                on:click|preventDefault={() => goTo("contact")}
                class="btn styleBtn"
                style=""
              >
                {$_("Get started")}
              </a>
            </div>
          </div>
        </div>

        <div
          class="col-12 col-md-12 col-lg-4 wow fadeInDown"
          data-wow-delay=".6s"
        >
          <div class="card">
            <div class="card-body text-center">
              <h2 class="card-title">
                {$_("Full")}
              </h2>
              <p class="card-text">
                {$_(
                  "Leverage the power of the entire Guesthub platform and take advantage of all its benefits to begin improving your guests’ experience."
                )}
              </p>
            </div>
            <div class="card-footer text-center">
              <a
                href="#!"
                on:click|preventDefault={() => goTo("contact")}
                class="btn styleBtn"
                style=""
              >
                {$_("Get started")}
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-80">
        <div
          class="col-12 pt-80 pb-80 wow fadeInUp spacing"
          data-wow-delay=".1s"
        >
          <div
            style="border-top: none;
                    border-left: none;
                    border-right: none; position: sticky;
                    top: 90px;
                    left: 0px;
                    right: 0px;
                    z-index: 2; "
            class="card"
          >
            <div class="row">
              <div class="col-6" style="padding: 0;" />
              <div class="col-3 text-center table-title-1" style="padding: 0;">
                <h3>
                  {$_("Starter")}
                </h3>
              </div>
              <div class="col-3 text-center table-title-1" style="padding: 0;">
                <h3>
                  {$_("Full")}
                </h3>
              </div>
            </div>
          </div>

          <div class="my-list">
            {#each plans as plan}
              <div
                class="card card-title-plan"
                style="padding: 5px 5px; border-radius:0px;"
              >
                <div class="row">
                  <div class="col-6 table-title-2">
                    <h4>
                      {$_(plan.title)}
                    </h4>
                  </div>
                  <div class="col-3" />
                  <div class="col-3" />
                </div>
              </div>

              {#each plan.items as item}
                <div class="card" style="padding: 5px 5px; border-radius:0px;">
                  <div class="row">
                    <div class="col-6 table-title-3">
                      <span>{$_(item.title)}</span>
                      {#if item.info}
                        <i
                          class="material-icons"
                          style="cursor: pointer; color: #686C6D; position: absolute;"
                          data-toggle="modal"
                          data-target={item.info}
                          >info
                        </i>
                      {/if}
                    </div>
                    <div
                      class="col-3 text-center table-title-plan"
                      style="padding: 0;"
                    >
                      {#if item.starter == "check"}
                        <i class="material-icons" style="color: #1AB394;"
                          >check_circle</i
                        >
                      {:else}
                        <span>{$_(item.starter)}</span>
                      {/if}
                    </div>
                    <div
                      class="col-3 text-center table-title-plan"
                      style="padding: 0;"
                    >
                      {#if item.full == "check"}
                        <i class="material-icons" style="color: #1AB394;"
                          >check_circle</i
                        >
                      {:else}
                        <span>{$_(item.full)}</span>
                      {/if}
                    </div>
                  </div>
                </div>
              {/each}
            {/each}
          </div>
        </div>
      </div>

      <div class="row mt-40">
        <div class="col-12">
          <div
            class="card wow fadeInUp"
            data-wow-delay="0.2s"
            style="background-color: #2F4050;border-radius: 0.8rem; "
          >
            <div class="row">
              <div
                class="col-12 col-md-9 card-plans-last createplansDesk paddingCreate"
              >
                <h2 class="card-title" style="color: #FFFFFF;">
                  {$_("We can create a plan according to your needs")}
                </h2>
                <p class="card-text" style="color: #FFFFFF; ">
                  {$_(
                    "Each of our modules can be purchased separately, allowing us to build a plan according to the needs of your property."
                  )}
                </p>
              </div>

              <div class="col-12 col-md-3 text-center">
                <a
                  href="#!"
                  on:click|preventDefault={() => goTo("contact")}
                  class="btn styleBtn createPlansMd"
                >
                  {$_("Get started")}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<InfoNumberOfRequests />
<InfoEscalations />
<InfoBasicSupport />
<InfoEmailSupport />

<style>
  .styleImgPricing {
    display: flex !important;
    align-content: center !important;
    flex-wrap: wrap !important;
  }
  .styleTextPricing {
    display: flex;
    align-content: center !important;
    flex-wrap: wrap !important;
  }
</style>
