<script>
    import { _ } from "svelte-i18n";
</script>

<div
    class="modal fade"
    id="infoNumberOfRequests"
    tabindex="-1"
    role="dialog"
    aria-labelledby="infoNumberOfRequestsTitle"
    aria-hidden="true"
>
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">
                    {$_("Number of request")}
                </h5>
                <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                {$_("Total number of tickets that can be created in one month")}
            </div>
        </div>
    </div>
</div>
