<script>
    import HeroComponent from "../hero/component.svelte";
    import BenefitsComponent from "../benefits/component.svelte";
    import ProductsComponent from "../products/component.svelte";
    import IntegrationsComponent from "../integrations/component.svelte";
    import ContactComponent from "../contact/component.svelte";
    import { onMount } from "svelte";

    onMount(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    });
</script>

<svelte:head>
    <title>GuestHub</title>
    <meta property="og:title" content="GuestHub" />
</svelte:head>

<HeroComponent />
<BenefitsComponent />
<ProductsComponent />
<IntegrationsComponent />
<ContactComponent />
