<script>
    import { _ } from "svelte-i18n";

    export let onlyEnglish;

    let integrations = [
        {
            class: "",
            name: "",
            imagen: "/img/integrations/1.png",
            href: null,
        },
        {
            class: "",
            name: "",
            imagen: "/img/integrations/2.png",
            href: null,
        },
        {
            class: "",
            name: "",
            imagen: "/img/integrations/3.png",
            href: null,
        },
        {
            class: "",
            name: "",
            imagen: "/img/integrations/4.png",
            href: null,
        },
        {
            class: "",
            name: "",
            imagen: "/img/integrations/5.png",
            href: null,
        },
        {
            class: "",
            name: "",
            imagen: "/img/integrations/6.png",
            href: null,
        },
        {
            class: "",
            name: "",
            imagen: "/img/integrations/7.png",
            href: null,
        },
        {
            class: "",
            name: "",
            imagen: "/img/integrations/8.png",
            href: null,
        },
        {
            class: "",
            name: "",
            imagen: "/img/integrations/9.png",
            href: null,
        },
        {
            class: "",
            name: "",
            imagen: "/img/integrations/10.png",
            href: null,
        },
        {
            class: "",
            name: "",
            imagen: "/img/integrations/11.png",
            href: null,
        },
        {
            class: "",
            name: "",
            imagen: "/img/integrations/12.png",
            href: null,
        },
        {
            class: "",
            name: "",
            imagen: "/img/integrations/13.png",
            href: null,
        },
        {
            class: "",
            name: "",
            imagen: "/img/integrations/14.png",
            href: null,
        },
        {
            class: "",
            name: "",
            imagen: "/img/integrations/15.png",
            href: null,
        },
        {
            class: "",
            name: "",
            imagen: "/img/integrations/16.png",
            href: null,
        },

        {
            class: "",
            name: "",
            imagen: "/img/integrations/17.png",
            href: null,
        },

        {
            class: "",
            name: "",
            imagen: "/img/integrations/18.png",
            href: null,
        },
    ];
</script>

<section id="integrations">
    <section
        class="testimonail area pt-120 pb-160 pt-sm-80 pb-sm-80"
        data-background="img/bg/about-bg1.jpg"
        style="background-color: #2F4050; padding: 80px 0;"
    >
        <div class="container">
            <div class="text-center wow fadeInDown" data-wow-delay=".6s">
                <div class="section-titles">
                    <h1 style="color: white;">
                        {#if onlyEnglish}
                            We work with software that you already know
                        {:else}
                            {$_("We work with software that you already know")}
                        {/if}
                    </h1>
                </div>
            </div>

            <div class="row" style="justify-content: center;">
                {#each integrations as integration}
                    <div
                        class="col-6 col-md-4 col-lg-2{!integration.imagen
                            ? integration.class
                            : ''} wow fadeInDown"
                        data-wow-delay=".6s"
                    >
                        {#if integration.imagen}
                            <img
                                class="wow fadeInUp"
                                data-wow-delay=".6s"
                                src={integration.imagen}
                                alt=""
                                style="width: 100%;"
                            />
                        {/if}
                    </div>
                {/each}
            </div>
        </div>
    </section>
</section>
