<script>
    import { locale, _ } from "svelte-i18n";

    const changeLanguage = (lang) => {
        locale.set(lang);
        localStorage.setItem("lang", lang);
    };
</script>

<section class="features-area pt-120 pb-160 pt-sm-80 pb-sm-80">
    <div class="container-fluid cloubs">
        <div class="row">
            <div
                class="col-xl-8 offset-xl-2 col-lg-8 offset-lg-2 wow fadeInDown"
                data-wow-delay=".6s"
            >
                <div class="section-titles text-center">
                    <img
                        class="wow fadeInUp"
                        data-wow-delay=".6s"
                        src="/img/integrations/cloudbeds.png"
                        alt=""
                    />

                    <span class="textCloud">
                        <p class="textClouds">
                            {$_(
                                "Cloudbeds is the fastest growing hospitality management software suite in the world, trusted by more than 20,000 hotels, hostels, inns, and alternative accommodations in more than 155 countries. The Cloudbeds suite includes a PMS that’s powerful, easy to use and completely integrated."
                            )}
                        </p>
                    </span>
                    <span style="margin-top: 0px;" class="textCloud">
                        <p class="textClouds">
                            {$_(
                                "To see how to integrate Guesthub with Cloudbeds"
                            )}
                            <a href="/support-cloudbeds"
                                ><b style="color: #1AB394;"> {$_("click here")} </b></a
                            >
                        </p>
                    </span>
                </div>
                <div style="text-align: center;">

                    <span style="justify-content: center;">
                        <a
                            href="https://www.cloudbeds.com/property-management-system/"
                            class="btn btn-primary styleBtn"
                        >
                            {$_("To know more about click here")}
                        </a>
                    </span>
                </div>
            </div>
        </div>
    </div>
</section>
