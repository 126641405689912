<script>
  import { _, locale } from "svelte-i18n";
  import ModalVideoComponent from "../modal-video.component.svelte";

  let modalOpenVideo = false;
  const configurationVideo = {
    title: "How the filtering works? ",
    videoUrl: "https://www.youtube.com/embed/yNENVZFHutQ?si=nqJ-FHEUe6gKAFbc",
  };
  const goTo = (idSection) =>
    window.scrollTo({
      top: document.getElementById(idSection).offsetTop,
      behavior: "smooth",
    });
</script>

<div class="container">
  <div class="row">
    <div class="col-12 col-md-6 d-flex align-items-center">
      <div class="benefits-area-tre txtBenefist3">
        <div class="section-titles-one-page mb-35">
          <h1 class="wow fadeInUp" data-wow-delay=".6s">
            {$_("Own your Guest")}
          </h1>

          <div class="col-sm-12 d-sm-block d-md-none mt-20 mb-20 p-0">
            <div
              class="features-img nav overflow product-tabs hideScroll justify-content-center"
            >
              <img
                class="wow fadeInUp"
                data-wow-delay=".6s"
                src="/img/benefits/OwnyourGuest.webp"
                alt={$_(
                  "Property indicators to identify opportunities for improvement",
                )}
                style="width: 100%;"
              />
            </div>
          </div>

          <p
            style="margin-top: 5%; text-align: left;"
            class="wow fadeInUp"
            data-wow-delay=".6s"
          >
            {$_(
              "Have methods in place to capture your guest’s contact information (email, phone number, etc.)",
            )}
          </p>
          <a
            on:click|preventDefault={() => (modalOpenVideo = true)}
            href="javascript:;"
            data-wow-delay=".6s"
            class="mt-40 modal-video-size wow fadeInUp"
          >
            <!-- svelte-ignore a11y-missing-attribute -->
            <i class="material-icons buttomPlay"> play_circle</i>
            <p class="text-video">How it works?</p>
          </a>
        </div>
      </div>
    </div>
    <div class="col-md-6 d-none d-md-flex align-items-center">
      <div class="features-img">
        <img
          class="wow fadeInUp imgDesk3"
          data-wow-delay=".6s"
          src="/img/benefits/OwnyourGuest.webp"
          alt={$_(
            "Property indicators to identify opportunities for improvement",
          )}
          style="width: 100%;"
        />
      </div>
    </div>
  </div>
</div>
{#if modalOpenVideo}
  <ModalVideoComponent
    config={configurationVideo}
    on:close={() => (modalOpenVideo = false)}
  ></ModalVideoComponent>
{/if}

<style>
  .modal-video-size {
    text-align: left;
    display: flex;
    cursor: pointer;
  }
  .buttomPlay {
    color: var(--green-1-ab-394, var(--Green-1AB394, #1ab394));
    font-size: 2.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.625rem;
  }
  .text-video {
    color: var(--green-1-ab-394, var(--Green-1AB394, #1ab394));
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.625rem; /* 162.5% */
  }
  .text-video:hover {
    text-decoration-line: underline;
  }
</style>
