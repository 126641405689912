<script>
    import { _ } from "svelte-i18n";
    export let stickyHeader;

    setTimeout(() => (stickyHeader = true), 100);
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
</script>

<svelte:head>
    <title>{$_("Privacy Policy - GuestHub")}</title>
    <meta property="og:title" content={$_("Privacy Policy - GuestHub")} />
</svelte:head>

<section class="features-area pt-100 pb-120">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12" style="padding: 40px 0;">
                <div class="container">
                    <h1>{$_("Privacy Policy")}</h1>
                    <h2>{$_("Norelian (the “Company”)")}</h2>
                    <p>
                        is committed to maintaining robust privacy protections
                        for its users. Our Privacy Policy (“Privacy Policy”) is
                        designed to help you understand how we collect, use and
                        safeguard the information you provide to us and to
                        assist you in making informed decisions when using our
                        Service.
                    </p>
                    <p>
                        For purposes of this Agreement, “Site” refers to the
                        website, owned and operated by Company, which can be
                        accessed at&nbsp;<a href="https://www.norelian.com/"
                            >Norelian.com</a
                        >&nbsp;and&nbsp;<a
                            href="https://guesthub.qa.norelian.com/"
                            >Guesthub.io&nbsp;</a
                        >“Service(s)” refers to the Company’s services
                        accessible via the Site or through integration with the
                        Site. The terms “we,” “us,” and “our” refer to the
                        Company. “You” refers to you, as a visitor or user of
                        our Site or our Service, as either a hotel employee or a
                        guest. By accessing and or using our Site or our
                        Service, you hereby accept our Privacy Policy and Terms
                        of Use which can be found at
                        http://norelian.com/privacypolicy and you consent to our
                        collection, storage, use and disclosure of your Personal
                        Information and Non-Personal Information as described in
                        this Privacy Policy.
                    </p>
                    <p>
                        Please review the following terms carefully. By
                        accessing and/or using the Site and/or Services, whether
                        paid or not, you hereby agree to each of the terms and
                        conditions set forth herein. Company reserves the right
                        to modify its Privacy Policy, at any time, without
                        notice. It is your responsibility to check our Privacy
                        Policy periodically for changes. Your use of the Site
                        and/or Services following any modifications signifies
                        your agreement to accept and be bound by our Privacy
                        Policy, as modified. If you do not agree to our Privacy
                        Policy in its entirety, do not access or use the Site or
                        Services.
                    </p>
                    <p>
                        <b>Information We Collect</b><br />Our Services are
                        accessible by both hotel employees and hotel guests. In
                        both instances, we collect “Non-Personal Information”
                        and “Personal Information.” which may vary depending
                        upon your status as either a hotel employee or guest and
                        the type of modules or integrations which are included
                        as part of the Services. Non-Personal Information
                        includes information that cannot be used to personally
                        identify You, such as anonymous usage data, general
                        demographic information we may collect, referring/exit
                        pages and URLs, platform types, preferences you submit
                        and preferences that are generated based on the data you
                        submit and number of clicks. Personal Information may
                        include your email address, full name, phone number,
                        confirmation number, number of companions, number of
                        bags, arrival gate and terminal information, a brief
                        physical description of you, room number, arrival date,
                        departure date, and any other identifying information
                        which is submitted to us either through the registration
                        process at the Site, application on the Site, or system
                        interface.
                    </p>
                    <p>
                        <i>Information Collected via Technology.</i>&nbsp;In an
                        effort to improve the quality of our Services and/or
                        enhance the User experience, we track information
                        provided to us by your browser or by our software
                        application when you view or use the Services. We track
                        this information using cookies, or small text files
                        which include an anonymous unique identifier. Cookies
                        are sent to a user’s browser from our servers and are
                        stored on the user’s computer hard drive. Sending a
                        cookie to a user’s browser enables us to collect
                        Non-Personal information about that user and keep a
                        record of the user’s preferences when utilizing our
                        services, both on an individual and aggregate basis. The
                        Company may use both persistent and session cookies;
                        persistent cookies remain on your computer after you
                        close your session and until you delete them, while
                        session cookies expire when you close your browser.
                    </p>
                    <p>
                        <i>Location information.</i>&nbsp;We collect information
                        about your location when you use our driver app even
                        when the app is closed or not in use, which helps us
                        keep the property and guest informed of the vehicle’s
                        position at all times.<br />Information displayed to the
                        guest: The location information allows Guesthub to show
                        the guest the ETA (estimated time of arrival) and
                        vehicle’s position on a map.<br />Information displayed
                        to the property: The location information allows
                        Guesthub to show the vehicle position to the property on
                        the monitoring tool map. Also, if the vehicle’s location
                        information is not receiving for period longer that the
                        one allowed, a request will be automatically generated
                        in Guesthub in order to notify the personnel in charge.<br
                        /><br />Your location can be determined with varying
                        degrees of accuracy by:<br />– GPS<br />– IP address<br
                        />– Sensor data from your device<br />– Information
                        about things near your device, such as Wi-Fi access
                        points, cell towers, and Bluetooth-enabled devices<br
                        /><br />The types of location data we collect depend in
                        part on your device and account settings. For example,
                        you can turn your Android device’s location on or off
                        using the device’s settings app.
                    </p>
                    <p>
                        <i
                            >Information you provide us by registering for an
                            account.</i
                        >&nbsp;To become an account holder and subscriber to the
                        Services, you will need to create a user profile. You
                        can create a profile by registering with the Service and
                        entering your email address, and creating a user name
                        and a password. By registering and/or using the
                        Services, in any capacity whatsoever, you are
                        authorizing us to collect, store and use your email
                        address in accordance with this Privacy Policy.
                    </p>
                    <p>
                        <i>Children’s Privacy.</i>&nbsp;The Site and the
                        Services are not directed to anyone under the age of 13.
                        The Site does not knowingly collect or solicit
                        information from anyone under the age of 13, or allow
                        anyone under the age of 13 to sign up for the Service.
                        In the event that we learn that we have gathered
                        personal information from anyone under the age of 13
                        without the consent of a parent or guardian, we will
                        delete that information as soon as possible. If you
                        believe we have collected such information, please
                        contact us at&nbsp;<a href="mailto:legal@norelian.com"
                            >legal@norelian.com</a
                        >
                    </p>
                    <p>
                        <b>How We Use and Share Information</b><br />Personal
                        Information. Except as otherwise stated in this Privacy
                        Policy, we do not sell, trade, rent or otherwise share
                        for marketing purposes your Personal Information with
                        third parties. We do share Personal Information with: i.
                        authorized hotel employees who are using the Services as
                        part of their job responsibilities; ii. vendors whose
                        applications are integrated with our Services; and iii.
                        vendors who are performing services for the Company.
                        These vendors use your Personal Information only at our
                        direction and in accordance with our Privacy Policy. In
                        general, the Personal Information you provide to us is
                        used to enable us to provide the Services to You and to
                        help us communicate with You. Notwithstanding, we may
                        share Personal Information with outside parties if we
                        have a good-faith belief that access, use, preservation
                        or disclosure of the information is reasonably necessary
                        to include, without limitation, meeting any applicable
                        legal process or enforceable governmental request,
                        taking precautions against liability, enforcing Terms of
                        Service, investigating potential violations, addressing
                        fraud, security or technical concerns, or protecting
                        against harm to the rights, property, or safety of our
                        users or the public as required or permitted by law.
                    </p>
                    <p>
                        <i>Non-Personal Information.</i>&nbsp;In general, we use
                        Non-Personal Information to help us improve our Service
                        and customize the user experience. We also aggregate
                        Non-Personal Information in order to track trends and
                        analyze use patterns on the site. Note that this Privacy
                        Policy does not limit, in any way, our use or disclosure
                        of Non-Personal Information and we reserve the right to
                        use and disclose such Non-Personal Information to our
                        partners, advertisers, agents, and other third parties,
                        at our sole discretion.
                    </p>
                    <p>
                        <b>How We Protect Information</b><br />We implement
                        security measures designed to protect your information
                        from unauthorized access. Your account is protected by
                        your account password and we urge you to take steps to
                        keep your personal information safe by not disclosing
                        your password and by logging out of your account after
                        each use. We further protect your information from
                        potential security breaches by implementing certain
                        technological security measures including encryption,
                        firewalls and secure socket layer technology. However,
                        these measures do not guarantee that your information
                        will not be accessed, disclosed, altered or destroyed by
                        breach of such firewalls and secure server software. By
                        accessing and/or using our Site or Service, you
                        acknowledge that you understand and agree to assume
                        these risks.
                    </p>
                    <p>
                        <b
                            >Your Rights Regarding the Use of your Personal
                            Information</b
                        ><br />You have the right at any time to prevent us from
                        contacting you for marketing purposes. When we send a
                        promotional communication to a user, the user can opt
                        out of further promotional communications by following
                        the unsubscribe instructions provided in each
                        promotional e-mail. Please note that notwithstanding the
                        promotional preferences you request be unsubscribed, we
                        may continue to send you administrative emails in
                        connection with the Services. In the event we undergo a
                        business transaction such as a merger, acquisition by
                        another company, or sale of all or a portion of our
                        assets, your Personal Information may be among the
                        assets transferred. You acknowledge and consent that
                        such transfers may occur and are permitted by this
                        Privacy Policy, and that any acquirer of our assets may
                        continue to process your Personal Information as set
                        forth in this Privacy Policy.
                    </p>
                    <p>
                        <b>Links to Other Websites</b><br />As part of the
                        Service, we may provide links to or compatibility with
                        other websites or applications. However, we are not
                        responsible for the privacy practices employed by those
                        websites or the information or content they contain.
                        This Privacy Policy applies solely to information
                        collected by us through the Site and the Service.
                        Therefore, this Privacy Policy does not apply to your
                        use of a third party website accessed by selecting a
                        link on our Site or via our Service. To the extent that
                        you access or use the Service through or on another
                        website or application, then the privacy policy of that
                        other website or application will apply to your access
                        or use of that site or application. As such, we
                        encourage our users to read the privacy statements of
                        other websites before proceeding to use them.
                    </p>
                    <p><b>Miscellaneous</b></p>
                    <ol type="1">
                        <li>
                            If any part of this Privacy Policy is held or found
                            to be invalid or unenforceable, that portion of the
                            policy will be construed to be consistent with
                            applicable law, while the remaining portions of the
                            policy will remain in full force and effect. Any
                            failure on our part to enforce any provision of this
                            Privacy Policy will not be considered a waiver of
                            our right to enforce such provision. The terms of
                            this Privacy Policy shall not be construed against
                            the Company by virtue of its having drafted them.
                            Our rights under the Privacy Policy will survive any
                            termination of use of the Services.
                        </li>
                        <li>
                            The provisions of this Agreement are for the benefit
                            of you, the Company and its affiliates, officers,
                            directors, employees, agents, representatives,
                            vendors, licensors, licensees, suppliers and any
                            third party information providers to the Site or the
                            Services. Each of these individuals or entities
                            shall have the right to assert and enforce those
                            provisions directly against you on his, her or its
                            own behalf. We may assign or delegate these Terms of
                            Service and/or our Privacy Policy, in whole or in
                            part, to any person or entity at any time with or
                            without your consent. You may not assign or delegate
                            any rights or obligations under the Terms of Service
                            or Privacy Policy without our prior written consent,
                            and any unauthorized assignment or delegation by you
                            is void.
                        </li>
                        <li>
                            The Company will generally communicate with its
                            users by electronic means, such as email. You
                            consent to receive communications from us via the
                            email you provided us, and you understand and agree
                            that all agreements, notices, disclosures and other
                            communications that we provide to you electronically
                            satisfy any legal requirement that such
                            communications be in writing.
                        </li>
                        <li>
                            You agree that any cause of action related to or
                            arising out of your relationship with the Company
                            must commence within ONE year after the cause of
                            action accrues. Otherwise, such cause of action is
                            permanently barred. This agreement and your access
                            and use of the Site and Services are governed by the
                            federal laws of the United States of America and the
                            laws of the State of Florida, without regard to
                            conflict of law provisions. You hereby consent to
                            the exclusive jurisdiction of the federal or state
                            courts in and for Miami-Dade County and waive any
                            jurisdictional venue or inconvenient form objections
                            to such courts. You further agree to comply with any
                            applicable federal, state and local laws in
                            connection with your access and use of the Site and
                            Services. We reserve the right to seek all
                            additional remedies available to use at law and in
                            equity for violations of this agreement or otherwise
                            arising from the access or use of the Site or the
                            Services, including, but not limited to, injunctive
                            relief and monetary damages.
                        </li>
                        <li>
                            YOU ACKNOWLEDGE THAT YOU HAVE READ OUR TERMS OF USE,
                            UNDERSTAND ALL ASPECTS OF THE TERMS OF USE, AND WILL
                            BE BOUND BY ITS TERMS AND CONDITIONS.  WE RESERVE
                            THE RIGHT TO CHANGE OUR TERMS OF USE AT ANY TIME AND
                            AT OUR SOLE DISCRETION. UPDATED VERSIONS OF OUR
                            TERMS OF USE WILL APPEAR ON THIS SITE AND ARE
                            EFFECTIVE IMMEDIATELY. CONTINUED USE OF THE SITE OR
                            THE SERVICES AFTER ANY SUCH CHANGES CONSTITUTES YOUR
                            CONSENT TO SUCH CHANGES.
                        </li>
                    </ol>
                    <br /><br /><br />
                    <p>
                        <b>Contact Us</b><br />If you have any questions
                        regarding this Terms of Use Agreement, please contact us
                        by sending an email to&nbsp;<a
                            href="mailto:legal@norelian.com"
                            >legal@norelian.com</a
                        >
                    </p>
                    <br />
                    <p>
                        This term of use agreement was last updated on October
                        18, 2018
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>
