<script>
    import { _, locale } from "svelte-i18n";
    let viewMore = false;

    const goTo = (idSection) =>
        window.scrollTo({
            top: document.getElementById(idSection).offsetTop,
            behavior: "smooth",
        });
</script>

<div class="container-fluid">
    <div class="row">
        <div class="offset-md-1 col-md-5">
            <div class="benefits-area-tre txtBenefist3">
                <div class="section-titles mb-35">
                    <h1 class="wow fadeInUp  " data-wow-delay=".6s">
                        {$_("Reduce your operating expenses")}
                    </h1>

                    <div class="col-sm-12 d-sm-block d-md-none mt-20 mb-20">
                        <div class="features-img nav overflow product-tabs hideScroll justify-content-center">
                            <img
                                class="wow fadeInUp  responsiveSm"
                                data-wow-delay=".6s"
                                src="/img/benefits/benefits-3-{$locale}.png"
                                alt="{$_("Property indicators to identify opportunities for improvement")}"
                                style="width: 100%;"
                            />
                        </div>
                    </div>

                    <p
                        style="margin-top: 5%"
                        class="wow fadeInUp "
                        data-wow-delay=".6s"
                    >
                        {$_(
                            "Provide your management team with a dashboard to spot trends, make data driven decisions and work preventatively. Watch your costs go down and your online reputation soar."
                        )}
                    </p>

                    {#if viewMore}
                        <p style="margin: 15px 0 15px 0;">
                            {$_("Decrease the number of calls by allowing your guests to create and track their own requests. Give them the flexibility to choose how much or how little they want to interact with your staff.")}
                        </p>
                    {:else}
                        <p style="margin: 15px 0 15px 0;" />
                    {/if}

                    <a
                        style="color: #1AB394;"
                        class="wow fadeInUp "
                        data-wow-delay=".6s"
                        href="#!"
                        on:click={() => (viewMore = !viewMore)}
                    >
                        {$_(viewMore ? "Hide" : "Learn more")}
                        <i style="position: absolute;" class="material-icons">
                            {viewMore ? "expand_less" : "expand_more"}
                        </i>
                    </a>
                    <div class="mt-40">
                        <a
                            style="color: white;"
                            class="btn wow fadeInUp  styleBtn"
                            data-wow-delay=".6s"
                            href="#!"
                            on:click|preventDefault={() => goTo("contact")}
                            >{$_("Book demo")}</a
                        >
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-5 d-none d-md-block">
            <div class="features-img">
                <img
                    class="wow fadeInUp imgDesk3 "
                    data-wow-delay=".6s"
                    src="/img/benefits/benefits-3-{$locale}.png"
                    alt="{$_("Property indicators to identify opportunities for improvement")}"
                    style="width: 100%;"
                />
            </div>
        </div>
    </div>
</div>
