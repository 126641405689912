<script>
    import { _ } from "svelte-i18n";
    export let stickyHeader;

    setTimeout(() => (stickyHeader = true), 100);
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
</script>

<svelte:head>
    <title>{$_("Terms & Conditions - GuestHub")}</title>
    <meta property="og:title" content={$_("Terms & Conditions - GuestHub")} />
</svelte:head>

<section class="features-area pt-100 pb-120">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12" style="padding: 40px 0;">
                <div class="container">
                    <h1>{$_("Terms & Conditions")}</h1>
                    <h2>{$_("For purposes of this agreement,")}</h2>
                    <p>
                        “Site” refers to the website, owned and operated by
                        Company, which can be accessed at norelian.com and
                        guesthub.io “Service” refers to the Company’s services
                        accessed via the Site or through integration with the
                        Site. The terms “we,” “us,” and “our” refer to the
                        Company. “You” refers to you, as a visitor or user of
                        our Site or our Service, as either a hotel employee or a
                        guest. By accessing and or using our Site or our
                        Service, you hereby accept our Terms of Use.
                    </p>
                    <p>
                        Please review the following terms carefully. By
                        accessing and/or using the Site and/or Services, whether
                        paid or not, you hereby agree to each of the terms and
                        conditions set forth herein. Company reserves the right
                        to modify the Terms of Use, at any time, without notice.
                        It is your responsibility to check these Terms of Use
                        periodically for changes. Your use of the Site and/or
                        Services following any modifications signifies your
                        agreement to accept and be bound by the Terms of Use, as
                        modified. If you do not agree to the Terms of Use in
                        their entirety, do not access or use the Site or
                        Services.
                    </p>
                    <p>
                        <b>Privacy Policy</b><br />Company respects the privacy
                        of its Service users. Please refer to the Company’s
                        Privacy Policy which explains how we collect, use, and
                        disclose information that pertains to your privacy. When
                        you access or use the Services, you signify your
                        agreement to the Privacy Policy as well as these Terms
                        of Use.
                    </p>
                    <p>
                        <b>About the Service</b><br />Guesthub is an end-to end
                        guest engagement software as a service platform via a
                        web interface for assisting hotels in managing and
                        tracking guest requests, lost &amp; found, package
                        tracking, airport shuttle tracking, pickup service and
                        guest satisfaction survey.
                    </p>
                    <p><b>Use of site</b></p>
                    <ol type="1">
                        <li>
                            <i>Content.</i> All text, graphics, logos, user interfaces,
                            images, photos, trademarks, artwork, designs, computer
                            code and layout that appears on the Site (“Content”)
                            is owned or licensed by or to Company and is protected
                            by intellectual property laws. You may access the Site
                            and the Content only for purposes to learn about Company,
                            its products and services or for a specific purpose related
                            to the Services. Company transfers no right, title or
                            interest in the Content to you, whether as a result of
                            your downloading or printing content or otherwise. You
                            may not use, alter, copy, republish, transmit, or otherwise
                            distribute, in any way (including “mirroring”) any portion
                            of the Site or its Content, or create another work based
                            on our Content or the Site, without Norelian’s prior
                            written consent. Your unauthorized use of the Content
                            and Site is strictly prohibited.
                        </li>
                        <li>
                            <i>Registration.</i> Certain sections of the Site or
                            use of the Services may require you to register an account
                            with us. If registration is requested, you agree to provide
                            us with accurate, complete registration information.
                            It is your responsibility to keep that information up-to-date
                            by contacting us with the updated information. Each registration
                            is for a single user only. We do not permit: (a) any
                            other person using the registered sections under your
                            name or (b) access through a single name being made available
                            to multiple users on a network. You are responsible for
                            preventing such unauthorized use. We expressly disclaim
                            any and all liability in connection with the registration,
                            maintenance and security of your registration. If you
                            believe there has been unauthorized use, you must notify
                            us immediately.
                        </li>
                        <li>
                            <i>Age Restrictions.</i> You represent and warrant that
                            you have the legal power, authority and capacity to accept
                            the Terms of Use. You represent and warrant that you
                            are either more than 18 years of age, or an emancipated
                            minor 16 years or older, or possess legal parental or
                            guardian consent, and are fully able and competent to
                            understand and agree to the terms, conditions, obligations,
                            affirmations, representations and warranties set forth
                            in the Terms of Use. If you do not meet the conditions
                            above, you may not access the Site or use the Services.
                        </li>
                        <li>
                            <i>Prohibited Uses.</i> As a condition of your
                            accessing and/or using the Site or Services, you
                            hereby agree to comply with the following
                            prohibitions of use. We reserve the right to
                            terminate any user’s access or use of the Site
                            without notice if such user’s access or use of the
                            Site or Services, in any manner, whatsoever,
                            violates the following prohibitions of use, in
                            addition to any other terms set forth herein, and to
                            delete any data stored in or on behalf of such
                            user’s account:
                            <ul>
                                <li>
                                    You may not access and/or use the Site or
                                    Service for any purpose that is unlawful or
                                    prohibited by these Terms of Use.
                                </li>
                                <li>
                                    You may not access or use the Site or
                                    Services in any manner that could damage,
                                    disable, overburden, or impair the Company’s
                                    server, or the network(s) connected to the
                                    Company’s server, or interfere with any
                                    other party’s use and enjoyment of the Site
                                    or the Services.
                                </li>
                                <li>
                                    You may not attempt to gain unauthorized
                                    access to the Site or any Services, other
                                    accounts, computer systems or networks
                                    connected to any Company server or to any of
                                    the Services, through hacking, password
                                    mining or any other means.
                                </li>
                                <li>
                                    You may not obtain or attempt to obtain any
                                    Content or materials or information through
                                    any means not intentionally made available
                                    through the Site or the Services.
                                </li>
                                <li>
                                    You may not decompile, disassemble, or
                                    reverse engineer any of the software or
                                    Content used in any part of the Site or the
                                    Services or place, send or otherwise
                                    transmit any virus or use any malicious
                                    programs or techniques in connection with
                                    the Site or Services.
                                </li>
                            </ul>
                        </li>
                    </ol>
                    <p>
                        <b>Intellectual Property Rights</b><br />You acknowledge
                        and agree that the Company owns and shall own all right,
                        title and interest, throughout the world, in and to the
                        Site and the Services and all copyrights, trademarks,
                        service marks and other intellectual property rights
                        associated therewith. You shall not take any action that
                        is inconsistent with our ownership of the Site and the
                        Services. You further acknowledge and agree that nothing
                        in this Agreement and no use of the Site or the Services
                        shall cause to vest or be construed to vest in you any
                        right, title or interest in or to the Site or the
                        Services other than the express right to use the Site or
                        the Services solely in accordance with the terms and
                        conditions of this Agreement. Any rights not expressly
                        granted herein are reserved.
                    </p>
                    <p>
                        <b>Third Party Links</b><br />We provide links to third
                        party Sites solely as a convenience to you. These links
                        are not controlled by or affiliated with us in any
                        manner, whatsoever. We have not reviewed these Sites nor
                        is are we responsible for the offerings of any of these
                        sites or the content, privacy policies or terms of use
                        of these Sites. Any third party links that we provide on
                        our Site does not constitute or imply any endorsement,
                        sponsorship or recommendation by us of that third party,
                        its Site or the contents of its Site. You acknowledge
                        and agree that if you use those third party links, you
                        will be subject to that third party’s terms of use, its
                        privacy policy and information security policy and any
                        other terms, policies, conditions or agreements
                        applicable to that third party’s Site. You further
                        acknowledge and agree that we are not responsible or
                        liable, directly or indirectly, for any damage or loss
                        caused or alleged to be caused by or in connection with
                        your use of or reliance on any content or other
                        information available on any third party app or website.
                        You may not link or otherwise connect our Site or any
                        Content without our prior written permission.
                    </p>
                    <p>
                        <b>Third Party Content and References</b><br />Third
                        party content may appear or be accessible through
                        website links on or from the Site or through use of the
                        Services. We shall not be responsible for and assume no
                        liability for any mistakes, errors, inaccuracies,
                        misstatements of law, defamation, slander, libel,
                        omissions, falsehood, obscenity, pornography or
                        profanity in the statements, opinions or other
                        representations in any such third party content
                        appearing on or accessible from the Site or through use
                        of the Services. You acknowledge and agree that the
                        information, views and opinions expressed in the third
                        party content represent solely the thoughts of the
                        author and are neither endorsed by nor do they reflect
                        the belief of the Company. It is your responsibility to
                        evaluate, assess and bear the risk associated with the
                        use of or reliance on any such content. Any and all
                        references to any Services, recording, event, process,
                        publication, Service or offering of any third party by
                        name, trade name, trademark or otherwise does not
                        necessarily constitute or imply our endorsement or
                        recommendation of such by the Company.
                    </p>
                    <p>
                        <b>Disclaimer</b><br />THE SITE OR SERVICES ARE PROVIDED
                        ON AN “AS IS” AND “AS AVAILABLE” BASIS. THE COMPANY
                        EXPRESSLY DISCLAIMS ALL WARRANTIES, INCLUDING THE
                        WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                        PURPOSE AND NON-INFRINGEMENT. WITHOUT LIMITING THE
                        GENERALITY OF THE FOREGOING, THE COMPANY HEREBY
                        SPECIFICALLY DISCLAIMS ALL RESPONSIBILITY OR LIABILITY
                        FOR ANY LOSS, INJURY, CLAIM, OR DAMAGE OF ANY KIND
                        RESULTING FROM, ARISING OUT OF, OR IN ANY WAY RELATED TO
                        (A) ANY THIRD PARTY APPLICATIONS OR WEBSITES OR CONTENT
                        THEREIN DIRECTLY OR INDIRECTLY ACCESSED THROUGH LINKS IN
                        THE SITE OR THE SERVICES, INCLUDING BUT NOT LIMITED TO
                        ANY ERRORS IN OR OMISSIONS THEREFROM, (B) THE
                        UNAVAILABILITY OF THE SITE OR THE SERVICES OR ANY
                        PORTION THEREOF, (C) YOUR ACCESS AND USE OF THE SITE OR
                        THE SERVICES, (D) YOUR USE OF ANY EQUIPMENT, TECHNOLOGY,
                        OR SOFTWARE IN CONNECTION WITH THE SITE OR THE SERVICES,
                        (E) THE SERVICES OR THE FUNCTIONS PERFORMED BY THE SITE
                        WILL BE UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE, OR
                        THAT DEFECTS IN THE SITE, CONTENT, OR SERVICES WILL BE
                        CORRECTED, (F) THE ACCURACY OR COMPLETENESS OF THE
                        CONTENT, AND (G) THE SITE OR SERVICES WILL BE SECURE OR
                        FREE FROM BUGS, VIRUSES OR OTHER PROGRAM LIMITATIONS.
                        THE COMPANY FURTHER SPECIFICALLY DISCLAIMS LIABILITY FOR
                        ANY LOSS OF DATA STORED ON OR BY MEANS OF THE SITE OR
                        USE OF THE SERVICES AND FOR ANY LOSS OF DATA OR OTHER
                        DAMAGE ARISING FROM SPYWARE, MALWARE OR OTHER
                        THIRD-PARTY CAUSES. TO THE EXTENT A PARTICULAR
                        JURISDICTION LIMITS OR DOES NOT ALLOW THE DISCLAIMER OF
                        IMPLIED OR OTHER WARRANTIES AS SET FORTH ABOVE, THEN THE
                        COMPANY SHALL BE ENTITLED TO THE BENEFIT OF SUCH
                        DISCLAIMERS AND LIMITATIONS TO THE HIGHEST EXTENT
                        PERMITTED BY APPLICABLE LAW.
                    </p>
                    <p>
                        <b>Limitation of Liability</b><br />YOUR SOLE AND
                        EXCLUSIVE REMEDY FOR ANY DISSATISFACTION OR DISPUTE WITH
                        THE COMPANY, THE SITE OR THE SERVICES IS TO DISCONTINUE
                        YOUR USE OF THE SITE OR THE SERVICES. IN NO EVENT WILL
                        COMPANY, ITS OWNERS, DIRECTORS, OFFICERS, EMPLOYEES OR
                        AGENTS BE LIABLE FOR ANY LOSS, INJURY, CLAIM, LIABILITY
                        OR DAMAGE OF ANY KIND, WHATSOEVER RESULTING FROM OR IN
                        CONNECTION WITH YOUR ACCESS OR USE OF THE SITE OR
                        SERVICES OR IN CONNECTION WITH ANY CLAIM ATTRIBUTABLE TO
                        ERRORS, OMISSIONS, OR OTHER INACCURACIES OR FAILURES IN
                        THE SITE, CONTENT OR SERVICES, EVEN IF THE COMPANY HAS
                        BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES,
                        INCLUDING WITHOUT LIMITATION, ANY SPECIAL, DIRECT,
                        INDIRECT, INCIDENTAL, PUNITIVE OR CONSEQUENTIAL DAMAGES
                        OF ANY KIND WHATSOEVER (INCLUDING, WITHOUT LIMITATION,
                        ATTORNEYS’ FEES) IN ANY WAY DUE TO, RESULTING FROM OR
                        ARISING IN CONNECTION WITH THE USE OF OR INABILITY TO
                        USE THE SITE OR THE SERVICES. TO THE EXTENT THIS
                        LIMITATION ON LIABILITY IS PROHIBITED OR FAILS OF ITS
                        ESSENTIAL PURPOSE, THE COMPANY’S SOLE OBLIGATION TO YOU
                        FOR DAMAGES SHALL BE LIMITED TO THE AMOUNT PAID BY YOU
                        UNDER THIS AGREEMENT OR $100.00, WHICHEVER IS LESS.
                    </p>
                    <p>
                        <b>Idemnification</b><br />You are solely liable for any
                        content, messages or other information you transmit,
                        provide or upload to the Site or through use of the
                        Services. You agree to indemnify, defend and hold
                        harmless the Company, its officers, directors, employees
                        and agents from any claim, action, demand, loss or
                        damages (including attorney’s fees and costs) arising
                        out of or in connection with (a) your violation of any
                        term of this Agreement, (b) your access and use of the
                        Site or Services, (c) any improper, unauthorized or
                        illegal uses of your profile and (d) your violation of
                        any rights of a third party, including without
                        limitation any property or privacy right.
                    </p>
                    <p><b>Miscellaneous</b></p>
                    <ol type="1">
                        <li>
                            If any part of this Terms of Use agreement is held
                            or found to be invalid or unenforceable, that
                            portion of the agreement will be construed to be
                            consistent with applicable law, while the remaining
                            portions of the agreement will remain in full force
                            and effect. Any failure on our part to enforce any
                            provision of the Terms of Use will not be considered
                            a waiver of our right to enforce such provision. The
                            terms of this Agreement shall not be construed
                            against the Company by virtue of its having drafted
                            them. Our rights under the Terms of Use will survive
                            any termination of use of the Services.
                        </li>
                        <li>
                            The provisions of this Agreement are for the benefit
                            of you, the Company and its affiliates, officers,
                            directors, employees, agents, representatives,
                            vendors, licensors, licensees, suppliers and any
                            third party information providers to the Site or the
                            Services. Each of these individuals or entities
                            shall have the right to assert and enforce those
                            provisions directly against you on his, her or its
                            own behalf. We may assign or delegate these Terms of
                            Service and/or our Privacy Policy, in whole or in
                            part, to any person or entity at any time with or
                            without your consent. You may not assign or delegate
                            any rights or obligations under the Terms of Service
                            or Privacy Policy without our prior written consent,
                            and any unauthorized assignment or delegation by you
                            is void.
                        </li>
                        <li>
                            The Company will generally communicate with its
                            users by electronic means, such as email. You
                            consent to receive communications from us via the
                            email you provided us, and you understand and agree
                            that all agreements, notices, disclosures and other
                            communications that we provide to you electronically
                            satisfy any legal requirement that such
                            communications be in writing.
                        </li>
                        <li>
                            You agree that any cause of action related to or
                            arising out of your relationship with the Company
                            must commence within ONE year after the cause of
                            action accrues. Otherwise, such cause of action is
                            permanently barred. This agreement and your access
                            and use of the Site and Services are governed by the
                            federal laws of the United States of America and the
                            laws of the State of Florida, without regard to
                            conflict of law provisions. You hereby consent to
                            the exclusive jurisdiction of the federal or state
                            courts in and for Miami-Dade County and waive any
                            jurisdictional venue or inconvenient form objections
                            to such courts. You further agree to comply with any
                            applicable federal, state and local laws in
                            connection with your access and use of the Site and
                            Services. We reserve the right to seek all
                            additional remedies available to use at law and in
                            equity for violations of this agreement or otherwise
                            arising from the access or use of the Site or the
                            Services, including, but not limited to, injunctive
                            relief and monetary damages.
                        </li>
                        <li>
                            YOU ACKNOWLEDGE THAT YOU HAVE READ OUR TERMS OF USE,
                            UNDERSTAND ALL ASPECTS OF THE TERMS OF USE, AND WILL
                            BE BOUND BY ITS TERMS AND CONDITIONS.  WE RESERVE
                            THE RIGHT TO CHANGE OUR TERMS OF USE AT ANY TIME AND
                            AT OUR SOLE DISCRETION. UPDATED VERSIONS OF OUR
                            TERMS OF USE WILL APPEAR ON THIS SITE AND ARE
                            EFFECTIVE IMMEDIATELY. CONTINUED USE OF THE SITE OR
                            THE SERVICES AFTER ANY SUCH CHANGES CONSTITUTES YOUR
                            CONSENT TO SUCH CHANGES.
                        </li>
                    </ol>
                    <div><br /></div>
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                    <p>
                        <b>Contact Us</b><br />If you have any questions
                        regarding this Terms of Use Agreement, please contact us
                        by sending an email to
                        <a href="mailto:legal@norelian.com"
                            >legal@norelian.com</a
                        >
                    </p>
                    <p><br /></p>
                    <p>
                        This term of use agreement was last updated on October
                        18, 2018
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>
