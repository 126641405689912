<script>
    import { _ } from "svelte-i18n";
    export let stickyHeader;

    setTimeout(() => (stickyHeader = true), 100);
    import ContactComponent from "../contact/component.svelte";
    import PacketPlansComponent from "./packet-plans.svelte";
    import PricingComponent from "./component.svelte";
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
</script>

<svelte:head>
    <title>{$_("Pricing - GuestHub")}</title>
    <meta property="og:title" content={$_("Pricing - GuestHub")} />
</svelte:head>

<PricingComponent />
<!--PacketPlansComponent /-->
<ContactComponent />
