<script>
  import { Router, Route } from "./pager";

  import HomePage from "./home/page.svelte";
  import TermsPage from "./terms/page.svelte";
  import PrivacyPolicyPage from "./privacy-policy/page.svelte";
  import CloudbedsPage from "./cloudbeds/page.svelte";
  import PricingPage from "./pricing/page.svelte";
  import SupportCloudbedsPage from "./support-cloudbeds/page.svelte";
  import LicenseAgreement from "./license-agreement/page.svelte";
  import Survey1 from "./surveys/survey-1.svelte";

  import OnePage1 from "./one-page/page-1/page.svelte";
  import OnePage2 from "./one-page/page-2/page.svelte";
  import OnePage3 from "./one-page/page-3/page.svelte";

  export let params;
  export let stickyHeader;

  /*const guard = (ctx, next) => {
    // check for example if user is authenticated
    if (true) {
      redirect("/login");
    } else {
      // go to the next callback in the chain
      next();
    }
  };*/

  let routes = [
    { path: "/", component: HomePage },
    { path: "/survey/camping", component: Survey1 },
    { path: "/one-page/1", component: OnePage1 },
    { path: "/one-page/better-ota", component: OnePage1 },
    { path: "/one-page/2", component: OnePage2 },
    { path: "/one-page/how-do-you-maintain-it", component: OnePage2 },
    { path: "/one-page/3", component: OnePage3 },
    { path: "/one-page/do-the-same-for-less", component: OnePage3 },
    { path: "/terms", component: TermsPage },
    { path: "/privacy-policy", component: PrivacyPolicyPage },
    { path: "/cloudbeds", component: CloudbedsPage },
    { path: "/cloudbeds/", component: CloudbedsPage },
    { path: "/pricing", component: PricingPage },
    { path: "/support-cloudbeds", component: SupportCloudbedsPage },
    { path: "/license-agreement", component: LicenseAgreement },
    { path: "**", component: HomePage },
  ];

  const redirectToHome = () => {
    redirect("/");
  };
</script>

<Router>
  {#each routes as route}
    <Route
      path={route.path}
      component={route.component}
      bind:stickyHeader
      {params}
    />
  {/each}
</Router>
