<script>
    import BenefitUnoComponent from "./1.component.svelte";
    import BenefitDosComponent from "./2.component.svelte";
    import BenefitTresComponent from "./3.component.svelte";
</script>

<section id="benefits">
    <section
        class="testimonail area pt-200 pb-200  pb-sm-80"
        data-background="img/bg/about-bg1.jpg"
    >
        <BenefitUnoComponent />
    </section>

    <section
        class="testimonail area pt-200 pb-200 pt-sm-80 pb-sm-80"
        data-background="img/bg/about-bg1.jpg"
        style="background-color: #2F4050;"
    >
        <BenefitDosComponent />
    </section>

    <section
        class="testimonail area pt-200 pb-120 pt-sm-80 pb-sm-80"
        data-background="img/bg/about-bg1.jpg"
    >
        <BenefitTresComponent />
    </section>
</section>
